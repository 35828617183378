import React, { useEffect, useState } from 'react'
import PageLine from '../PageLine'
import ReactPlayer from 'react-player'

import { directionsData } from '../../utils/content'
import { videoData } from '../VideoDownloadControl'

import {motion} from 'framer-motion'
import { useRef } from 'react'

import poster from '../../assets/img/poster-web.jpg'

import Accordion from '../Accordion'
import DirectionsCursor from '../DirectionsCursor'
import DirectionsBlocks from '../DirectionsBlocks'
import { Link } from 'react-router-dom'
import { mobileDetect } from '../../utils/helpersFunction'


import {ReactComponent as ArrowIcon} from '../../assets/img/icons/arrow-button.svg'
import { useTranslation } from 'react-i18next'

function Directions({videoIsLoop, isMobile, routePush, currentTab, setCurrentTab}) {

    const {t} = useTranslation();

    const [isTabChoosed, setIsTabChoosed] = useState(false);
    const [touchDevice, setTouchDevice] = useState(null);


    useEffect(() => {
        setCurrentTab({direction : "ВЕБ 3.0", choosed: false});
        setTouchDevice(mobileDetect());
    }, [])

    const playerRef1 = useRef(null);
    const playerRef2 = useRef(null);
    const playerRef3 = useRef(null);
    const playerRef4 = useRef(null);

    const playersRefs = {
        'ВЕБ 3.0': playerRef1,
        'ФІНТЕХ': playerRef2,
        'МАРКЕТИНГ': playerRef3,
        'IT АУТСОРС': playerRef4
    }

    const handleTabChange = (tab) => {
        const player = playersRefs[currentTab.direction].current;
        if (player) {
          player.seekTo(0, "seconds"); 
        } 

        if (!isTabChoosed) {
            setCurrentTab(state => ({...state, direction: tab}));
        }
    }

    const handleTab = (e) => {
        setIsTabChoosed(true)
        routePush(e, true ,'/contacts');
        setCurrentTab(state => (
            {...state, choosed: true}
        ));
    }

    const handleTabMobile = (tab) => {
        const player = playersRefs[currentTab.direction].current;
        if (player) {
          player.seekTo(0, "seconds"); 
        } 

        setCurrentTab(state => ({...state, direction: tab}));   
    }

    const handleContactMobile = (e, tab) => {
        e.stopPropagation();
        setIsTabChoosed(true)
        routePush(e, true ,'/contacts');
        setCurrentTab(state => (
            {...state, choosed: true}
        ));
        handleTabMobile(tab)
    }

    const directionsList = !touchDevice ? 
        directionsData.map((item, i) => (
            <div key={i} className={currentTab.direction === item.title ? 'directions__block-item directions__block-item-active' : 'directions__block-item'}>
            <Link to='/contacts' className="directions__block-top" 
                onMouseEnter={() => handleTabChange(item.title)} 
                onClick={(e) => handleTab(e)}
            >
                <div className="directions__block-top-title">{t(`directions.tabs.${item.title}.title`)}</div>
                <ArrowIcon />
            </Link>
            </div>
        )) 
        : 
        directionsData.map((item, i) => (
            <div key={i} className={currentTab.direction === item.title ? 'directions__block-item directions__block-item-active' : 'directions__block-item'}>
            <div className="directions__block-top" 
                onClick={() => handleTabChange(item.title)} 
            >
                <div className="directions__block-top-title">{t(`directions.tabs.${item.title}.title`)}</div>
                <Link to='/contacts' onClick={(e) => handleTab(e)}>
                    <ArrowIcon  style={{opacity:1}} />
                </Link>
            </div>
            </div>
        ));

    return (
        <motion.section className="directions"
            initial={{  opacity: 0 }}
            animate={videoIsLoop ? {  opacity: 1, transition: 0 } : {opacity: 0}}
            exit={{ opacity: 0, transition: {duration: 1, delay: 1}}}
        >
            <div className="directions__videos">
                <div className="directions__videos-overlay"></div>
                {
                    !isMobile ?
                    directionsData.map((item, i) => (
                        <ReactPlayer
                            poster={poster}
                            key={i}
                            ref={playersRefs[i]}
                            url={videoData['/directions'].directionsVideos[i]}
                            style={{ position:'fixed', top: 0, left: 0, opacity: currentTab.direction === item.title ? 1 : 0 }}
                            playing={videoIsLoop && currentTab.direction === item.title}
                            muted
                            width="100%"
                            height="100%"
                            position="fixed"
                            preload="auto"
                            playsInline
                        />
                    )) :
                    null
                }
            </div>
            <div className="directions__top">
				<div className="directions__line">
					<PageLine name={t('directions.page-line')}/>
				</div>
            </div>
            <div className="directions__wrapper">
                <div className="directions__block">
                    <div className="directions__block-left">
                        {directionsList}
                        <DirectionsCursor touchDevice={touchDevice} cursorWrapper={'.directions__block-left'}/>
                    </div>
                    <div className="directions__block-accordion">
                        {
                            directionsData.map((item, i) => (
                                <Accordion key={i} text={item.text} textMobile={item?.textMobile} title={item.title} handleContactMobile={handleContactMobile} handleTabMobile={handleTabMobile}/>
                            ))
                        }
                    </div>
                    <div className="directions__block-right">
                        <DirectionsBlocks videoIsLoop={videoIsLoop} currentItem={currentTab}/>
                    </div>
                </div>
            </div>
        </motion.section>
    )
}

export default Directions