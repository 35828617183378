
import { useEffect } from 'react';
import { scrollToTop } from '../../utils/helpersFunction';
import TermsLayout from '../TermsLayout';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const Terms = ({routePush , cookieHeigth}) => {
    const {t} = useTranslation();
    const data = {
        navLinks : ['Умови та положення', 'Кукіси', 'Ліцензія', 'Гіперпосилання на наш контент', 'iFrames', 'Відповідальність за зміст', 'Збереження прав', 'Видалення посилань з нашого сайту', 'Відмова від відповідальності'],
        title: 'Умови використання'
    }

    useEffect(() => {
        scrollToTop()
      }, [])

    return (
        <TermsLayout data={data} cookieHeigth={cookieHeigth}>
            <div className="terms__content-chapter chapter-0">
                <h2 className="terms__content-chapter-title">{t('terms.conditions.title')}</h2>
                <div className="terms__content-chapter-text">
                    <p>
                        {t('terms.conditions.p1')} <a href="https://uniqit.group">https://uniqit.group/</a>.
                    </p>
                    <p>
                        {t('terms.conditions.p2')}
                    </p>
                    <p>
                        {t('terms.conditions.p3')}
                    </p>
                </div>
            </div>
            <div className="terms__content-chapter chapter-1">
                <h2 className="terms__content-chapter-title">{t('terms.cookie.title')}</h2>
                <div className="terms__content-chapter-text">
                    <p>
                        {t('terms.cookie.p1')}
                    </p>
                    <p>
                        {t('terms.cookie.p2')}
                    </p>
                </div>
            </div>
            <div className="terms__content-chapter chapter-2">
                <h2 className="terms__content-chapter-title">{t('terms.licence.title')}</h2>
                <div className="terms__content-chapter-text">
                    <p>
                    {t('terms.licence.p1')}
                    </p>
                    <p>
                    {t('terms.licence.p2')}
                    </p>
                    <ul>
                        <li>{t('terms.licence.ul1.0')}</li>
                        <li>{t('terms.licence.ul1.1')}</li>
                        <li>{t('terms.licence.ul1.2')}</li>
                        <li>{t('terms.licence.ul1.3')}</li>
                    </ul>
                    <p>{t('terms.licence.p3')}</p>
                    <p>
                        {t('terms.licence.p4')}
                    </p>
                    <p>
                        {t('terms.licence.p5')}
                    </p>
                    <p>{t('terms.licence.p6')}</p>
                    <ul>
                        <li>{t('terms.licence.ul2.0')}</li>
                        <li>
                        {t('terms.licence.ul2.1')}
                        </li>
                        <li>
                        {t('terms.licence.ul2.2')}
                        </li>
                        <li>
                        {t('terms.licence.ul2.3')}
                        </li>
                    </ul>
                    <p>
                        {t('terms.licence.p7')}
                    </p>
                </div>
            </div>
            <div className="terms__content-chapter chapter-3 ">
                <h2 className="terms__content-chapter-title">{t('terms.hyper.title')}</h2>
                <div className="terms__content-chapter-text">
                    <p>
                        {t('terms.hyper.p1')}
                    </p>
                    <ul>
                        <li>{t('terms.hyper.ul1.0')}</li>
                        <li>{t('terms.hyper.ul1.1')}</li>
                        <li>{t('terms.hyper.ul1.2')}</li>
                        <li>{t('terms.hyper.ul1.3')}</li>
                        <li>{t('terms.hyper.ul1.4')}</li>
                    </ul>
                    <p>
                    {t('terms.hyper.p2')}
                    </p>
                    <p>{t('terms.hyper.p3')}</p>
                    <ul>
                        <li>{t('terms.hyper.ul2.0')}</li>
                        <li>{t('terms.hyper.ul2.1')}</li>
                        <li>{t('terms.hyper.ul2.2')}</li>
                        <li>{t('terms.hyper.ul2.3')}</li>
                        <li>{t('terms.hyper.ul2.4')}</li>
                        <li>{t('terms.hyper.ul2.5')}</li>
                        <li>{t('terms.hyper.ul2.6')}</li>
                    </ul>
                    <p>
                    {t('terms.hyper.p4')}
                    </p>
                    <p>
                    {t('terms.hyper.p5')}
                    </p>
                    <p>
                    {t('terms.hyper.p6')}
                    </p>
                    <p>{t('terms.hyper.p7')}</p>
                    <ul>
                        <li>{t('terms.hyper.ul2.0')}</li>
                        <li>{t('terms.hyper.ul2.1')}</li>
                        <li>{t('terms.hyper.ul2.2')}</li>
                    </ul>
                </div>
            </div>
            <div className="terms__content-chapter chapter-4">
                <h2 className="terms__content-chapter-title">IFrames</h2>
                <div className="terms__content-chapter-text">
                    <p>
                    {t('terms.iframe.p1')}
                    </p>
                </div>
            </div>
            <div className="terms__content-chapter chapter-5">
                <h2 className="terms__content-chapter-title">{t('terms.responsibility.title')}</h2>
                <div className="terms__content-chapter-text">
                    <p>
                    {t('terms.responsibility.p1')}
                    </p>
                </div>
            </div>
            <div className="terms__content-chapter chapter-6">
                <h2 className="terms__content-chapter-title">{t('terms.rights.title')}</h2>
                <div className="terms__content-chapter-text">
                    <p>
                    {t('terms.rights.p1')}
                    </p>
                </div>
            </div>
            <div className="terms__content-chapter chapter-7">
                <h2 className="terms__content-chapter-title">{t('terms.links.title')}</h2>
                <div className="terms__content-chapter-text">
                    <p>
                        {t('terms.links.p1')}
                    </p>
                </div>
            </div>
            <div className="terms__content-chapter chapter-8">
                <h2 className="terms__content-chapter-title">{t('terms.decline.title')}</h2>
                <div className="terms__content-chapter-text">
                    <p>
                        {t('terms.decline.p1')}
                    </p>
                    <ul>
                        <li>{t('terms.decline.ul1.0')}</li>
                        <li>{t('terms.decline.ul1.1')}</li>    
                        <li>{t('terms.decline.ul1.2')}</li>    
                        <li>{t('terms.decline.ul1.3')}</li>
                    </ul>
                    <p>
                        {t('terms.decline.p2')}    
                    </p>
                    <p>
                        {t('terms.decline.p3')}
                    </p>
                </div>
            </div>
            <div className="terms__content-chapter">
                <h2 className="terms__content-chapter-title">{t('terms.contact.title')}</h2>
                <div className="terms__content-chapter-text">
                    <p>
                        {t('terms.contact.p1')}
                    </p>
                    <ul>
                        <li>{t('terms.contact.ul1.0')} <a href="mailto:hello@uniqit.group"><strong>hello@uniqit.group</strong></a></li>
                        <li>{t('terms.contact.ul1.1')} <a href="https://uniqit.group/contacts"><strong>https://uniqit.group/contacts</strong></a></li>
                        <li>{t('terms.contact.ul1.2')} <a href="tel:+380502255535"><strong>+38 (050) 225-55-35</strong></a></li>
                    </ul>
                </div>
            </div>
            <div className="case__button terms__content-button">
                <Link to='/contacts' onClick={(e) => routePush(e, true ,'/contacts')} className='button button-black button--bestia'>
                    <span>{t('terms.button')}</span>
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6.63673 7.7143L6.63673 6.04788L14.541 6.04788V13.9522H12.8746V8.89281L7.22599 14.5414L6.04747 13.3629L11.6961 7.7143L6.63673 7.7143Z" fill="white"/>
                        </svg>
                    <div className="button__bg"></div>
                </Link>
            </div>
        </TermsLayout>
    )
}

export default Terms