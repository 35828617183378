import React , {useState, useEffect} from 'react'
import CaseHeader from '../CaseHeader'
import { scrollToTop } from '../../utils/helpersFunction'
import Cases from './Cases'
import { Link } from 'react-router-dom'
import { motion , cubicBezier } from 'framer-motion'

// import { caseImg } from '../../utils/content'


import leftArrow from '../../assets/img/icons/case-arrow.svg'


function SingleCase({handleAnimationDisable, currentSlide, isMobile ,routePush}) {


    const [fadeOut, setFadeOut] = useState(false);

    useEffect(() => {
        scrollToTop()
    }, [])


    const handleLinkBack = (e , route) => {
        handleAnimationDisable()
        routePush(e, true, route)
    }

    const handleLinkContact = (e , route, animateDisable) => {
        if (animateDisable) {
            setFadeOut(true);
        }
        routePush(e, true, route)
    }


    const containerBezier = cubicBezier(.65, 0, .15, 1);
    const wrapperBezier = cubicBezier(.5,0,0,1)
    
    const skewVariants = {
        initial: {scale: 1, skew: 0, WebkitFilter: 'blur(0)'},
        animate: {scale: .8, skew: '-5deg', WebkitFilter: 'blur(1.5px)', transition: {duration: 2.5, ease: wrapperBezier}}, 
        exit: {scale: 1, skew: 0, WebkitFilter: 'blur(0px)', transition: {duration: 2.5, ease: wrapperBezier}}
    }
    const translateVariants = {
        initial : {x: 0},
        animate : {x: `${isMobile ? '100vw' : '76vw'}`, transition: {delay: .8, duration: 2, ease: containerBezier}},
        exit:{x: 0, transition: {delay: .8, duration: 2, ease: containerBezier}}
    }

    return (

        <motion.div className='case'
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { duration: 0 } }}
            exit={fadeOut ? { opacity: 0, transition: { duration: 1} } : {}}
        >
            <motion.div className="case__right-animation"
                variants={!fadeOut ? translateVariants : {}}
                initial="initial"
                animate="animate"
                exit="exit"
            >
                <motion.div className='case__back'
                    variants={!fadeOut ? skewVariants : {}}
                    initial="initial"
                    animate="animate"
                    exit="exit"
                >
                    <Link to='/cases' 
                           onClick={e => handleLinkBack(e, '/cases')}
                    >
                    </Link>
                </motion.div>
                <motion.div className="case__right-wrapper"
                    variants={!fadeOut ? skewVariants : {}}
                    initial="initial"
                    animate="animate"
                    exit="exit"
                >
                        <CaseHeader/>
                        <Cases animate={false} exitDisabled={true} currentSlide={currentSlide}/>
                </motion.div>
            </motion.div>
            <motion.div className="case__main"
                initial={{opacity: 0 , x: '-100%'}}
                animate={{opacity: 1, x: 0, transition: {duration: 1, delay: 1.5}}}
                exit={!fadeOut ? {opacity: 0 , x: '-100%' , transition: {duration: 1}} : {}}
            >
                <picture>
                    <source srcSet={require('../../assets/img/mobile-bg.webp')} type="image/webp"/>
                    <img src={require('../../assets/img/mobile-bg.png')} alt='img' className="case__bg"></img>
                </picture>
                <div className="case__wrapper">
                    <div className="case__header">
                        <Link to={'/cases'}
                            onClick={e => handleLinkBack(e, '/cases')}
                        >
                            <img src={leftArrow} alt="arrow" />
                        </Link>
                        <div className="header__line case__header-line case__header-line-small">
                            <div className="header__line-block"></div>
                        </div>
                        <Link to={'/cases'}
                            onClick={e => handleLinkBack(e, '/cases')}
                        >
                            <div className="case__header-title">КЕЙСИ / BrokerNear</div>
                        </Link>
                        <div className="header__line case__header-line">
                            <div className="header__line-block"></div>
                        </div>
                    </div>
                    <div className="case__title-wrapper">
                        <h1 className="case__title">BrokerNear</h1>
                        <a href="https://brokernear.com/" rel='noreferrer' target='_blank' className="cases__slide-link link link--iocaste">
                            brokernear.com
                            <svg className="link__graphic link__graphic--slide" width="300%" height="100%" viewBox="0 0 1200 60" preserveAspectRatio="none">
                                <path d="M0,56.5c0,0,298.666,0,399.333,0C448.336,56.5,513.994,46,597,46c77.327,0,135,10.5,200.999,10.5c95.996,0,402.001,0,402.001,0"></path>
                            </svg>
                        </a>
                    </div>
                    <div className="case__technology">
                        <div className="case__technology-stack">
                            <div className="case__technology-block">Маркетинг</div>
                            <div className="case__technology-block">it аутсорс</div>
                        </div>
                        <div className="case__technology-year">
                            <div className="case__technology-block">2023</div>
                        </div>
                    </div>
                    <picture>
                        <source srcSet={require('../../assets/img/brokerNear-1.webp')} type="image/webp"/>
                        <img src={require('../../assets/img/brokerNear-1.png')} alt="Rihon concept" className="case__img-main case__img-border" />
                    </picture>
                    <p className="case__text mb-32">
                        <b>BrokerNear</b> - онлайн-портал, який об'єднує клієнтів і брокерські компанії. Користувачі можуть швидко знаходити найбільш релевантного брокера для своїх інвестиційних потреб.
                    </p>
                
                    <div className="case__line"></div>

                    <div className="broker">
                        <div className="broker__block">
                            <h3 className='case__subtitle'>Виконання:</h3>
                            <ul className="mt-48">
                                <li className='case__text'>Розробка різноманітного цільвого контенту - навчальні матеріали, аналітичні огляди та поради від експертів.</li>
                                <li className='case__text'>Розробка привабливого та інтуїтивно зрозумілого дизайну платформи.</li>
                                <li className='case__text'>Визначення та реалізація стратегії просування, включаючи фінансові форуми, рекламні кампанії та співпрацю з фінансовими блогерами.</li>
                                <li className='case__text'>Технічне налаштування проекту згідно рекомендацій пошукових мереж.</li>
                                <li className='case__text'>Формування методології оцінки брокерів на основі досвіду фінансових експертів.</li>
                            </ul>
                        </div>
                        <div className="broker__block">
                            <h3 className='case__subtitle'>Переваги BrokerNear:</h3>
                            <ul className="mt-48">
                                <li className='case__text'>Широкий вибір брокерських компаній: BrokerNear пропонує велику кількість надійних брокерів з різними фінансовими послугами, що дозволяє клієнтам знайти оптимального партнера.</li>
                                <li className='case__text'>Зручність та ефективність: Платформа пропонує інтуїтивно зрозумілий інтерфейс, який дозволяє легко здійснювати пошук, порівняння та відстеження інвестиційних можливостей.</li>
                                <li className='case__text'>Персоналізований досвід: BrokerNear надає можливість налаштування особистого профілю та отримання індивідуальних рекомендацій щодо вибору брокера.</li>
                            </ul>
                        </div>
                        <div className="broker__block">
                            <h3 className='case__subtitle'>Перспективи:</h3>
                            <ul className="mt-48">
                                <li className='case__text'>Подальший розвиток та вдосконалення платформи BrokerNear на основі зворотного зв'язку користувачів та вдосконалення функціональності.</li>
                                <li className='case__text'>Розширення співпраці зі знаковими гравцями у фінансовому секторі та приваблення нових клієнтів.</li>
                                <li className='case__text'>Посилення просування проекту розміщенням інфоповодів на зовнішніх фінансових порталах та форумах.</li>
                                <li className='case__text'>Представлення та розробка стратегії просування в соціальних мережах.</li>
                            </ul>
                        </div>
                    </div>

                    <div className="case__line"></div>


                    <div className="case__subtitle mb-24">Зв'язатись з нами</div>
                    <p className="case__text case__connect">Ви можете замовити <Link to='/contacts'
                                onClick={(e) => handleLinkContact(e ,'/contacts', true)}
								>консультаційний дзвінок</Link> або просто надіслати електронний лист на <b>hello@uniqitgroup.com</b></p>
                    <div className="case__button case__button-contacts">
                        <Link to='/contacts' onClick={(e) => handleLinkContact(e, '/contacts', true)} className='button button-black button--bestia'>
                            <span>Контакти</span>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6.63673 7.7143L6.63673 6.04788L14.541 6.04788V13.9522H12.8746V8.89281L7.22599 14.5414L6.04747 13.3629L11.6961 7.7143L6.63673 7.7143Z" fill="white"/>
                                </svg>
                            <div className="button__bg"></div>
                        </Link>
                    </div>
                </div>
            </motion.div>
        </motion.div>
    )
}

export default SingleCase