import React , {useEffect, useState} from 'react'
import CaseHeader from '../CaseHeader'
import Cases from './Cases'
import { Link, useLocation } from 'react-router-dom'
import { scrollToTop } from '../../utils/helpersFunction'
import { motion , cubicBezier } from 'framer-motion'
import YouTubeFrame from '../YouTubeFrame'
import { Swiper, SwiperSlide } from 'swiper/react';
import { rihonImages } from '../../utils/content'


import 'swiper/css';

import {ReactComponent as LeftArrow} from '../../assets/img/icons/case-arrow.svg'


function SingleCase({handleAnimationDisable, currentSlide , isMobile ,routePush}) {

    const [fadeOut, setFadeOut] = useState(false);

    useEffect(() => {
        scrollToTop()
    }, [])

    const handleLinkBack = (e , route) => {
        handleAnimationDisable()
        routePush(e, true, route)
    }

    const handleLinkContact = (e , route, animateDisable) => {
        if (animateDisable) {
            setFadeOut(true);
        }
        routePush(e, true, route)
    }


    const containerBezier = cubicBezier(.65, 0, .15, 1);
    const wrapperBezier = cubicBezier(.5,0,0,1)
    
    const skewVariants = {
        initial: {scale: 1, skew: 0, WebkitFilter: 'blur(0)'},
        animate: {scale: .8, skew: '-5deg', WebkitFilter: 'blur(3px)', transition: {duration: 2.5, ease: wrapperBezier}}, 
        exit: {scale: 1, skew: 0, WebkitFilter: 'blur(0px)', transition: {duration: 2.5, ease: wrapperBezier}}
    }
    const translateVariants = {
        initial : {x: 0},
        animate : {x: `${isMobile ? '100vw' : '76vw'}`, transition: {delay: .8, duration: 2, ease: containerBezier}},
        exit:{x: 0, transition: {delay: .8, duration: 2, ease: containerBezier}}
    }

    return (
        <motion.div className='case'
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { duration: 0 } }}
            exit={fadeOut ? { opacity: 0, transition: { duration: 1} } : {}}
        >
            <motion.div className="case__right-animation"
                variants={!fadeOut ? translateVariants : {}}
                initial="initial"
                animate="animate"
                exit="exit"
            >
                <motion.div className='case__back'
                    variants={!fadeOut ? skewVariants : {}}
                    initial="initial"
                    animate="animate"
                    exit="exit"
                >
                    <Link to='/cases' 
                            onClick={(e) => handleLinkBack(e, '/cases')}
                    >
                    </Link>
                </motion.div>
                <motion.div className="case__right-wrapper"
                    variants={!fadeOut ? skewVariants : {}}
                    initial="initial"
                    animate="animate"
                    exit="exit"
                >
                        <CaseHeader/>
                        <Cases animate={false} exitDisabled={true} currentSlide={currentSlide}/>
                </motion.div>
            </motion.div>
            <motion.div className="case__main"
                initial={{opacity: 0 , x: '-100%'}}
                animate={{opacity: 1, x: 0, transition: {duration: 1, delay: 1.5}}}
                exit={!fadeOut ? {opacity: 0 , x: '-100%' , transition: {duration: 1}} : {}}
            >
                <picture>
                    <source srcSet={require('../../assets/img/mobile-bg.webp')} type="image/webp"/>
                    <img src={require('../../assets/img/mobile-bg.png')} alt='img' className="case__bg"></img>
                </picture>
                <div className="case__wrapper">
                    <div className="case__header">
                        <Link to={'/cases'}
                            onClick={e => handleLinkBack(e, '/cases')}
                        >
                            <LeftArrow />
                        </Link>
                        <div className="header__line case__header-line case__header-line-small">
                            <div className="header__line-block"></div>
                        </div>
                        <Link to={'/cases'}
                            onClick={e => handleLinkBack(e, '/cases')}
                        >
                            <div className="case__header-title">КЕЙСИ / rihon</div>
                        </Link>
                        <div className="header__line case__header-line">
                            <div className="header__line-block"></div>
                        </div>
                    </div>
                    <div className="case__title-wrapper">
                        <h1 className="case__title">Rihon</h1>
                        <a href="https://rihongames.com" rel='noreferrer' target='_blank' className="cases__slide-link link link--iocaste">
                            rihongames.com
                            <svg className="link__graphic link__graphic--slide" width="300%" height="100%" viewBox="0 0 1200 60" preserveAspectRatio="none">
                                <path d="M0,56.5c0,0,298.666,0,399.333,0C448.336,56.5,513.994,46,597,46c77.327,0,135,10.5,200.999,10.5c95.996,0,402.001,0,402.001,0"></path>
                            </svg>
                        </a>
                    </div>
                    <div className="case__technology">
                        <div className="case__technology-stack">
                            <div className="case__technology-block">ВЕБ 3.0</div>
                            <div className="case__technology-block">Маркетинг</div>
                            <div className="case__technology-block">it аутсорс</div>
                        </div>
                        <div className="case__technology-year">
                            <div className="case__technology-block">2022</div>
                        </div>
                    </div>
                    <picture>
                        <source srcSet={require('../../assets/img/cases-1.webp')} type="image/webp"/>
                        <img src={require('../../assets/img/cases-1.png')} alt="Rihon concept" className="case__img-main" />
                    </picture>
                    <p className="case__text mb-32">
                        Rihon є частиною нового, інноваційного покоління відеоігор ВЕБ 3.0, у якому гравці можуть заробляти NFT і криптовалюту, роблячи внески в екосистему.
                    </p>
                    <h3 className="case__subtitle">
                    Найважливіші характеристики гри:
                    </h3>
                    <ul className='case__list mb-32'>
                        <li>Відчуйте захоплюючу рольову пригоду в динамічній економіці на основі Веб3 із захоплюючою механікою автоматичного бою.</li>
                        <li>Візьміть участь в унікальному ігровому процесі за допомогою NFT, який винагороджує гравців за їхній внесок в екосистему Rihon.</li>
                    </ul>
                    <p className="case__text">Rihon звертається до того факту, що вся земна куля стоїть на порозі нескінченного циклу кровопролиття та воєн, які забирають життя, не приносячи жодної користі. Через розповідь про Rihon ми хочемо продемонструвати, що в конфліктах немає переможців і що лише мирне вирішення проблем може дати людству шанс.</p>
                    <div className="case__line"></div>
                    <picture>
                        <source srcSet={require('../../assets/img/case-img.jpg')} type="image/webp"/>
                        <img src={require('../../assets/img/case-img.jpg')} alt="Rihon concept" className="case__img-main-2" />
                    </picture>
                    <h3 className='case__subtitle mt-48 mb-24'>Головні герої</h3>
                    <p className="case__text">Головні герої нашої гри - професійні найманці, здатні виконати навіть найскладнішу місію. Вони дуже різні, але об'єднані спільною метою - вижити в суворому і ворожому світі. Міра може здатися жорсткою, але в глибині душі вона вірить у світле майбутнє. Шеку, навпаки, не вірить жодній живій людині в цей ненависний йому час. Крім Міри – його подруги дитинства, яка таємно його кохає…</p>
                    <div className="case__slider mt-48">
                    
                        <Swiper
                            spaceBetween={16}
                            slidesPerView="auto"
                            >
                                {
                                    rihonImages.map((el, i) => (
                                        <SwiperSlide key={i}>
                                            <picture>
								                <source srcSet={el.img.webp} type="image/webp"/>
                                                <img src={el.img.jpg} alt="Rihon" />
                                            </picture>
                                        </SwiperSlide>
                                    ))
                                }
                        </Swiper>
                    </div>
                    <div className="case__button">
                        <a href='https://opensea.io/rihon' target='_blank' rel='noreferrer' className=' button button-black button--bestia'>
                            <span>перейти на opensea</span>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6.63673 7.7143L6.63673 6.04788L14.541 6.04788V13.9522H12.8746V8.89281L7.22599 14.5414L6.04747 13.3629L11.6961 7.7143L6.63673 7.7143Z" fill="white"/>
                                </svg>
                            <div className="button__bg"></div>
                        </a>
                    </div>
                    <div className="case__line"></div>
                    <div className="case-video">
                        <div className="case-video__video">
                            <div className="case-video__img">
                                <YouTubeFrame video="2OYVrFu6_Xw" height="100%" width="100%" />
                            </div>
                        </div>
                        <div className="case-video__rigth">
                            <div className="case-video__top">
                                <div className="case-video__title case__subtitle">Демо геймплею</div>
                                <div className="case__technology-block">АЛЬФА ВЕРСІЯ</div>
                            </div>
                            <p className="case__text case-video__text">Наша мета — перенести гравців у постапокаліптичний світ за допомогою рольової гри, яка поєднує в собі елементи мандрівних ігор з автоматичними боями.</p>
                        </div>
                    </div>
                    <div className="case__line"></div>
                    <div className="case__subtitle case__subtitle-contact mb-24">Зв'язатись з нами</div>
                    <p className="case__text case__connect">Ви можете замовити <Link to='/contacts'
									onClick={(e) => handleLinkContact(e ,'/contacts', true)}
								>консультаційний дзвінок</Link> або просто надіслати електронний лист на <b>hello@uniqitgroup.com</b></p>
                    <div className="case__button case__button-contacts">
                        <Link to='/contacts' onClick={e => handleLinkContact(e, '/contacts', true)} className='button button-black button--bestia'>
                            <span>Контакти</span>
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6.63673 7.7143L6.63673 6.04788L14.541 6.04788V13.9522H12.8746V8.89281L7.22599 14.5414L6.04747 13.3629L11.6961 7.7143L6.63673 7.7143Z" fill="white"/>
                                </svg>
                            <div className="button__bg"></div>
                        </Link>
                    </div>
                </div>
            </motion.div>
        </motion.div>
    )
}

export default SingleCase