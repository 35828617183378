
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { memo } from 'react';

import video1 from '../assets/video/01__static.mp4';
import video2 from '../assets/video/02__trans.mp4';
import video3 from '../assets/video/03__static.mp4';
import video4 from '../assets/video/04__trans.mp4';
import video5 from '../assets/video/05__trans.mp4';
import video60 from '../assets/video/06__web3.0.mp4';
import video61 from '../assets/video/06__fintech.mp4';
import video62 from '../assets/video/06__marketing.mp4';
import video63 from '../assets/video/06__code.mp4';
import video7 from '../assets/video/07__last.mp4';

import videoIpad1 from '../assets/video/ios/01_Static.mp4'
import videoIpad2 from '../assets/video/ios/02_Trans.mp4'
import videoIpad3 from '../assets/video/ios/03_Static.mp4'
import videoIpad4 from '../assets/video/ios/04_Trans.mp4'
import videoIpad5 from '../assets/video/ios/05_Trans.mp4'
import videoIpad60 from '../assets/video/ios/06_Web3.0.mp4'
import videoIpad61 from '../assets/video/ios/06_Fintech.mp4'
import videoIpad62 from '../assets/video/ios/06_Marketing.mp4'
import videoIpad63 from '../assets/video/ios/06_Code.mp4'
import videoIpad7 from '../assets/video/ios/07_Last.mp4'

const descVideo = [video1, video2, video3, video4, video5,video60, video61, video62, video63, video7];
const iosVideos = [videoIpad1, videoIpad2, videoIpad3, videoIpad4, videoIpad5, videoIpad60, videoIpad61, videoIpad62, videoIpad63, videoIpad7]

export const videoData = {
    '/': {
      loopedVideo: null,
      transitionVideo: null,
      duration: 5500,
    },
    '/about': {
      loopedVideo: null,
      transitionVideo: null,
      duration: 5825,
    },
    '/cases': {
      loopedVideo: null,
      transitionVideo: null,
      duration: 4300
    },
    '/directions': {
      loopedVideo: null,
      transitionVideo: null,
      disableLoop: true,
      directionsVideos: {
        0 : null,
        1 : null,
        2 : null,
        3 : null
      }
    },
    '/contacts': {
      loopedVideo: null,
      transitionVideo: null
    },
    '/about-us': {
      loopedVideo: null,
      transitionVideo: null
    },
    '/cases/rihon' : {
      loopedVideo: null,
      transitionVideo: null
    },
    '/cases/ezra' : {
      loopedVideo: null,
      transitionVideo: null
    },
    '/cases/crypto-accounting' : {
      loopedVideo: null,
      transitionVideo: null
    },
    '/cases/brokernear' : {
      loopedVideo: null,
      transitionVideo: null
    },
    '/terms' : {
      loopedVideo: null,
      transitionVideo: null
    },
    '/policy' : {
      loopedVideo: null,
      transitionVideo: null
    }
  };


const VideoPlayer = memo(({setIsLoading , isMobile, setCurrentVideo}) => {

  const [mobileDisable, setMobileDisable] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (!isMobile) {
      setMobileDisable(true)
    }
  }, [isMobile])

  useEffect(() => {
    
    if (setIsLoading && mobileDisable) {
        cacheVideos()
    }

  }, [setIsLoading, mobileDisable]);

  function iOS() {

    return [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform)
    // iPad on iOS 13 detection
    || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    || navigator.platform === "MacIntel";
  }

  // console.log('fff')
  const cacheVideos = async () => {
    try {
      const cache = await window.caches.open('video-pre-cache');
      
      const isIos = iOS();

      // if (!isIos) {
      //   for (const videoFileUrl of descVideo) {
      //     await fetchAndCache(videoFileUrl, cache);
      //   }
      // } else {
      //   for (const videoFileUrl of iosVideos) {
      //     await fetchAndCache(videoFileUrl, cache);
      //   }
      // }

  
      await putVideo(isIos);
  
      setCurrentVideo({
        loopedVideo: videoData[location.pathname]?.loopedVideo,
        transitionVideo: videoData[location.pathname]?.transitionVideo
      });
  
      setIsLoading(false);
    } catch (error) {
      // Обробка помилки кешування відео
      console.error('Помилка кешування відео:', error);
    }
  };
  
  const getVideo = async (videoFileUrl) => {
      const cache = await window.caches.open('video-pre-cache');
      const cacheResponse = await cache.match(videoFileUrl);

      if (cacheResponse) {
          const videoBlob = await cacheResponse.blob();

          // Создаем URL-объект и устанавливаем его в атрибут src
          const videoObjectURL = URL.createObjectURL(videoBlob);
          // console.log('Видео получено из кэша:', videoBlob);

          return videoObjectURL;
          // Здесь вы можете выполнить дальнейшие действия с полученным видео.
      } else {
        return fetch(videoFileUrl, )
          .then(networkResponse => {
            console.log('загрузка из сети')
            if (networkResponse.ok) {
              return networkResponse.blob();
            } else {
              throw new Error(`Network response was not ok: ${networkResponse.status}`);
            }
          })
          .then(videoBlob => {
            // Кешуємо відео в кеші з використанням videoFileUrl як ключа.
            cache.put(videoFileUrl, new Response(videoBlob));
            const videoObjectURL = URL.createObjectURL(videoBlob);
            
            return videoObjectURL;
          });
      }
  }

    const putVideo = async (ios) => {
      if (!ios) {
        const cosmosVideo = await getVideo(video7)
        videoData['/'].loopedVideo = await getVideo(video1)
        videoData['/'].transitionVideo = await getVideo(video2)
        videoData['/about'].loopedVideo = await getVideo(video3)
        videoData['/about'].transitionVideo = await getVideo(video4)
        videoData['/cases'].transitionVideo = await getVideo(video5)
        videoData['/contacts'].loopedVideo = cosmosVideo
        videoData['/contacts'].transitionVideo = cosmosVideo
        videoData['/directions'].directionsVideos[0] = await getVideo(video60)
        videoData['/directions'].directionsVideos[1] = await getVideo(video61)
        videoData['/directions'].directionsVideos[2] = await getVideo(video62)
        videoData['/directions'].directionsVideos[3] = await getVideo(video63)
        videoData['/about-us'].loopedVideo = cosmosVideo
        videoData['/about-us'].transitionVideo = cosmosVideo
        videoData['/cases/brokernear'].loopedVideo = cosmosVideo
        videoData['/cases/brokernear'].transitionVideo = cosmosVideo
        videoData['/cases/crypto-accounting'].loopedVideo = cosmosVideo
        videoData['/cases/crypto-accounting'].transitionVideo = cosmosVideo
        videoData['/cases/ezra'].loopedVideo = cosmosVideo
        videoData['/cases/ezra'].transitionVideo = cosmosVideo
        videoData['/cases/rihon'].loopedVideo = cosmosVideo
        videoData['/cases/rihon'].transitionVideo = cosmosVideo
        videoData['/terms'].loopedVideo = cosmosVideo
        videoData['/terms'].transitionVideo = cosmosVideo
        videoData['/policy'].loopedVideo = cosmosVideo
        videoData['/policy'].transitionVideo = cosmosVideo
        
      } else {
        const cosmosVideoIpad = await getVideo(videoIpad7)
        videoData['/'].loopedVideo = await getVideo(videoIpad1)
        videoData['/'].transitionVideo = await getVideo(videoIpad2)
        videoData['/about'].loopedVideo = await getVideo(videoIpad3)
        videoData['/about'].transitionVideo = await getVideo(videoIpad4)

        videoData['/cases'].transitionVideo = await getVideo(videoIpad5)
        videoData['/contacts'].loopedVideo = cosmosVideoIpad
        videoData['/contacts'].transitionVideo = cosmosVideoIpad
        videoData['/directions'].directionsVideos[0] = await getVideo(videoIpad60)
        videoData['/directions'].directionsVideos[1] = await getVideo(videoIpad61)
        videoData['/directions'].directionsVideos[2] = await getVideo(videoIpad62)
        videoData['/directions'].directionsVideos[3] = await getVideo(videoIpad63)
        videoData['/about-us'].loopedVideo = cosmosVideoIpad
        videoData['/about-us'].transitionVideo = cosmosVideoIpad
        videoData['/cases/brokernear'].loopedVideo = cosmosVideoIpad
        videoData['/cases/brokernear'].transitionVideo = cosmosVideoIpad
        videoData['/cases/crypto-accounting'].loopedVideo = cosmosVideoIpad
        videoData['/cases/crypto-accounting'].transitionVideo = cosmosVideoIpad
        videoData['/cases/ezra'].loopedVideo = cosmosVideoIpad
        videoData['/cases/ezra'].transitionVideo = cosmosVideoIpad
        videoData['/cases/rihon'].loopedVideo = cosmosVideoIpad
        videoData['/cases/rihon'].transitionVideo = cosmosVideoIpad
        videoData['/terms'].loopedVideo = cosmosVideoIpad
        videoData['/terms'].transitionVideo = cosmosVideoIpad
        videoData['/policy'].loopedVideo = cosmosVideoIpad
        videoData['/policy'].transitionVideo = cosmosVideoIpad
      }
    }


    // function fetchAndCache(videoFileUrl, cache) {
    //   // Сначала перевіряємо, чи відео вже є в кеші.
    //   return cache.match(videoFileUrl)
    //     .then(cacheResponse => {
    //       // Повертаємо кешовану відповідь, якщо відео вже є в кеші.
    //       if (cacheResponse) {
    //         console.log('видео загруженно из кеша')
    //         return cacheResponse;
    //       }
    //     });
    // }
    
});

export default VideoPlayer;
