import React , {useEffect, useState} from 'react';
import { Trans } from 'react-i18next';
import LinksBlock from '../LinksBlock'
import {motion} from 'framer-motion'


import {ReactComponent as ScrollIcon} from '../../assets/img/icons/scroll.svg'
import { useTranslation } from 'react-i18next'

const Home = ({transitionVideoLoaded , isMobile}) => {
	// const [homeAnimTrigger, setHomeAnimTrigger] = useState();
	const {t} = useTranslation();

	let homeAnimTrigger = null;

	if (isMobile) {
		homeAnimTrigger = isMobile
	} else {
		homeAnimTrigger = !transitionVideoLoaded
	}

	return (
		<motion.section className="home"
			initial={{ opacity: 0 }}
			animate={homeAnimTrigger ? {  opacity: 1, transition: 1} : {opacity: 0}}
			exit={{ opacity: 0, transition: {duration: 1}}}
		>	
			<picture>
				<source srcSet={require('../../assets/img/home-bg-mob.webp')} type="image/webp"/>
				<img className='home__bg-mob' src={require('../../assets/img/home-bg-mob.png')} alt="home background" />
			</picture>
			<div className="home__wrapper">
				<motion.h1 className="home__title"
					initial={{opacity: 0, y: '-50%'}}
					animate={homeAnimTrigger ? {opacity:1, y: 0, transition: {duration: 0.7, delay: 0.5}} : ''}
					exit={{opacity: 0, y: '-50%', transition: {duration: 0.7}}}
				>
					<Trans i18nKey="home.title" components={{ 1: <br /> }} />
				</motion.h1>
				<motion.h2 className="home__subtitle"
					initial={{opacity: 0, y: '-50%'}}
					animate={homeAnimTrigger ? {opacity:1, y: 0, transition: {duration: 0.7, delay: 0.7}} : ''}
					exit={{opacity: 0, y: '-50%', transition: {duration: 0.7}}}
				>
					{t('home.subtitle')}
				</motion.h2>
			</div>
			<motion.div className="home__block"
				initial={{opacity: 0, y: '100%'}}
				animate={homeAnimTrigger ? {opacity:1, y: 0, transition: {duration: 1, delay: 0.2}} : ''}
				exit={{opacity: 0, y: '100%'}}
			>
				<div className="home__block-city">
						{t('home.we')}			
						<picture>
							<source srcSet={require('../../assets/img/ukraine.webp')} type="image/webp"/>
							<img src={require('../../assets/img/ukraine.png')} alt="Ukraine" />
						</picture>
						<span>{t('home.from')}</span>
				</div>
				<div className="home__block-links">
					<LinksBlock/>
				</div>
				<div className="home__block-scroll">
					<ScrollIcon />
					{t('home.scroll')}		
				</div>
			</motion.div>
		</motion.section>
	)
}

export default Home