import React from 'react'
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';



const HelmetBlock = () => {
    let title = '';
    const  {i18n} = useTranslation();
    const lang = i18n.language;

    const isEn = lang.startsWith('en');

    if (isEn) {
        title = 'Uniqit is a product-based IT'
    } else {
        title = 'Uniqit - продуктова ІТ-компанія'
    }
    
    return (
        <Helmet>
            <meta property="og:title" content={title} />
        </Helmet>
    )
}

export default HelmetBlock