import React, { useEffect, useState } from 'react'
import { directionsData } from '../utils/content'
import It from './directionsBlockItems/It'
import Fintech from './directionsBlockItems/Fintech'
import Marketing from './directionsBlockItems/Marketing'
import Web from './directionsBlockItems/Web'
import { AnimatePresence, motion } from 'framer-motion'

function DirectionsBlocks({ currentItem , videoIsLoop}) {
  const [currentItemDelayed, setCurrentItemDelayed] = useState('ВЕБ 3.0');


  useEffect(() => {
    const delay = 400; // Затримка в мілісекундах перед зміною ключа
    const timeout = setTimeout(() => {
      setCurrentItemDelayed(currentItem.direction);
    }, delay);

    return () => clearTimeout(timeout);
  }, [currentItem.direction]);

  let block = null;

  switch (currentItemDelayed) {
    case 'ВЕБ 3.0':
      block = <Web videoIsLoop={videoIsLoop}/>;
      break;
    case 'ФІНТЕХ':
      block = <Fintech />;
      break;
    case 'МАРКЕТИНГ':
      block = <Marketing directionsData={directionsData} />;
      break;
    case 'IT АУТСОРС':
      block = <It />;
      break;
    default:
      block = <Web />;
  }

  return (
    <AnimatePresence mode="wait">
      <motion.div
        style={{flex: 1}}
        key={currentItemDelayed !== undefined && currentItemDelayed}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, transition: { duration: 0.3 } }}
        exit={{ opacity: 0, transition: { duration: 0.3 } }}
      >
        {block}
      </motion.div>
    </AnimatePresence>
  )
}

export default DirectionsBlocks;
