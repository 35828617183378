import React, {useEffect, useState,useRef} from 'react';
import PageLine from '../PageLine';
import { motion } from 'framer-motion';
import ContactForm from '../form/ContactForm';
import FormMessage from '../../components/form/FormMessage'
import { scrollToTop } from '../../utils/helpersFunction';
import { useTranslation } from 'react-i18next';

function Contacts({ currentTab, routePush, setCookiesVisible, formData, setFormData , setCookieError, cookieError}) {

  const [status, setStatus] = useState({
    isSubmitting: false,
    sendingStatus: 'idle'
  });
  const [isScroll, setIsScroll] = useState(false);
  const scrollTimeout = useRef();

  const {t} = useTranslation();

  useEffect(() => {
    scrollToTop()
  }, [])

  const handleScroll = (e) => {
    const delta = e.deltaY;

    if (!isScroll) {
      e.stopPropagation()
    }

    clearTimeout(scrollTimeout.current);
    scrollTimeout.current = setTimeout(() => {
    if (document.documentElement.scrollTop === 0 && !isScroll && delta < 0) {
        setIsScroll(true)
    }
    }, 250);
  }

  return (
    <motion.section
      className={status.isSubmitting ? 'contacts contacts__index' : 'contacts' }
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 1 } }}
      exit={{ opacity: 0, transition: { duration: 1, delay: 1 } }}
      onWheel={handleScroll}
    >
      {status.isSubmitting && 
      <div className="contacts__overlay">
        <FormMessage status={status}/>
      </div>}
      <div className="contacts__wrapper">
        <div className="contacts__line">
          <PageLine name={t('contacts.page-line')} />
        </div>
        <div className="contacts__block">
          <div className="contacts__block-wrapper">
            {/* <video playsInline style={{width: '100%'}} src={video} controls autoPlay></video> */}

            <ContactForm status={status} setStatus={setStatus} currentTab={currentTab} routePush={routePush} setCookiesVisible={setCookiesVisible} formData={formData} setFormData={setFormData} setCookieError={setCookieError} cookieError={cookieError}/>
            <div className="contacts__right">
              <picture>
								<source srcSet={require('../../assets/img/ukraine.webp')} type="image/webp"/>
								<img className='contacts__flag' src={require('../../assets/img/ukraine.png')} alt="Ukraine" />
							</picture>
              <div className="contacts__adress">
                {t('contacts.city')}
              </div>
              <div className="contacts__right-line"></div>
              <a href="tel:+380502255535" className="contacts__phone">
                +38 (050) 225-55-35
              </a>
              <a href="mailto:hello@uniqit.group" className="contacts__mail">
                hello@uniqit.group
              </a>
            </div>
          </div>
        </div>
      </div>
    </motion.section>
  );
}

export default Contacts;
