import { useEffect, useState } from "react";
import {motion, useMotionValue} from 'framer-motion'
import {ReactComponent as Arrow} from '../assets/img/icons/arrow-button.svg'

const DirectionsCursor = ({ cursorWrapper, touchDevice }) => {
  const [isActive, setActive] = useState(false);
 
  const cursorX = useMotionValue(-100)
  const cursorY = useMotionValue(-100)


  useEffect(() => {
    const handleMouseMove = (e) => {
      cursorX.set(e.clientX - 50)
      cursorY.set(e.clientY - 50) 
    };

    const cursorWrap = document.querySelector(cursorWrapper);

    if (window.innerWidth > 767) {
      cursorWrap.addEventListener('mousemove', handleMouseMove);
      cursorWrap.addEventListener('mouseover', () => setActive(true));
      cursorWrap.addEventListener('mouseleave', () => setActive(false));
    }

    return () => {
      if (window.innerWidth > 767) {
        cursorWrap.removeEventListener('mousemove', handleMouseMove);
        cursorWrap.removeEventListener('mouseover', () => setActive(true));
        cursorWrap.removeEventListener('mouseleave', () => setActive(false));
      }
    };
  }, [cursorWrapper]);

  return (
    <motion.div 
      className={`directions__cursor${isActive ? ' hover' : ''}`}
      style={{
        display: touchDevice ? 'none' : 'flex',
        translateX: cursorX,
        translateY: cursorY,
      }} 
    >
      <Arrow />
    </motion.div>
  );
};

export default DirectionsCursor;
