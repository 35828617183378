import React, { useState } from 'react'
import {ReactComponent as Icon} from '../../assets/img/icons/form-attention.svg'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next';

const FormCookieError = ({routePush, setCookiesVisible}) => {

    const [disableLink, setDisableLink] = useState(false);

    const setCookie = () => {
        setTimeout(() => {
            setCookiesVisible(true)
        }, 2000)
    }
    const {t} = useTranslation();
    const termsPush = (e) => {
        routePush(e, true, '/terms') ;
        setCookie();
        setDisableLink(true)
    }

    const policyPush = (e) => {
        routePush(e, true, '/terms') ;
        setCookie();
        setDisableLink(true)
    }

    return (
        <div className="contacts__cookies">
            <Icon/>{t('contacts.cookies.top')}{' '}
            <Link className={`${disableLink && 'link-disable'}`} to="/terms" onClick={e => termsPush(e)}>{t('contacts.cookies.terms')}</Link>{' '} {t('contacts.cookies.and')} {' '}
            <Link className={`${disableLink && 'link-disable'}`} to="/policy" onClick={e => policyPush(e)}>{t('contacts.cookies.policy')}</Link>
        </div>
      )
}

export default FormCookieError