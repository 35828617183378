import React, { useRef, useState } from 'react';
import { useSwipeable } from 'react-swipeable';

const SwipeableComponent = ({children, routePush}) => {
  const swipeUpRef = useRef(null);
  const swipeDownRef = useRef(null);

  const handleSwipeTop = () => {
      if (document.documentElement.scrollTop === 0) {
        if (swipeUpRef.current) {
          routePush(null, false, null, -10)
          setTimeout(() => {
            swipeUpRef.current = false;
          }, 1000)
        } else {
          swipeUpRef.current = true;
        }
      }
  }
  const handleSwipeDown = () => {
    if (Math.ceil(document.documentElement.scrollTop + document.documentElement.clientHeight) >= document.documentElement.scrollHeight) {

      if (swipeDownRef.current) {
        routePush(null, false, null, 10)
        setTimeout(() => {
          swipeDownRef.current = false
        }, 1000)
      } else {
        swipeDownRef.current = true;
      }
    }
  }

  const handlers = useSwipeable({
    onSwipedUp: () => {
      if (document.documentElement.scrollHeight > document.documentElement.clientHeight) {
        handleSwipeDown()
      } else {
        routePush(null, false, null, 10)
      }
    },
    onSwipedDown: () => {
      if (document.documentElement.scrollHeight > document.documentElement.clientHeight) { 
        handleSwipeTop()
      } else {
        routePush(null, false, null, -10)
      }
    },
    preventDefaultTouchmoveEvent: true,
    trackMouse: false
  });

  return (
    <div {...handlers}>
      {children}
    </div>
  );
};

export default SwipeableComponent;
