import React , {useState, useEffect} from 'react'
import { useTranslation } from 'react-i18next';

import {ReactComponent as ArrowDown}  from '../assets/img/icons/arrow-down.svg'
import en from '../assets/img/en.png';
import ua from '../assets/img/ukraine.png'
import { useLocation } from 'react-router-dom';

const HeaderLanguageToggle = () => {
    const [languageActive, setLanguageActive] = useState(false);
    const location = useLocation();

    useEffect(() => {
        if (languageActive) {
            setLanguageActive(prev => !prev);
        }
    }, [location])

    const { i18n } = useTranslation();

    const langData = {
        ua: {
            icon: ua,
            lang: 'UA'
        },
        en: {
            icon: en,
            lang: 'EN'
        }
    }

    let lang = '';
    
    if (i18n.language.startsWith('en')) {
        lang = 'en';
    } else {
        lang = 'ua';
    }    

    const secondLang = lang === 'ua' ? 'en' : 'ua';

    const toggleActive = () => {
        setLanguageActive(active => !active);
    };

    const toggleLanguage = () => {
        i18n.changeLanguage(lang === 'en' ? 'ua' : 'en');
    };
    
    return (
        <>
            <div className={languageActive ? 'header__language active' :'header__language'} 
                onClick={toggleActive}>
                <div className="header__language-item header__language-top">
                    {langData[lang]?.lang}
                    <ArrowDown className='header__language-arrow'/>
                </div>
                <div className="header__language-bottom"
                    onClick={toggleLanguage}
                >
                    <div className="header__language-item">
                        {langData[secondLang]?.lang}
                        <picture>
                            <img src={langData[secondLang]?.icon} alt="Arrow Down"/>
                        </picture>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HeaderLanguageToggle