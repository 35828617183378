
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import gif from '../../assets/img/marketing-gif.gif'
import { ReactComponent as CloseIcon} from '../../assets/img/icons/marketing-close.svg'
import { ReactComponent as HeartIcon} from '../../assets/img/icons/marketing-heart.svg'
import { ReactComponent as SmileIcon} from '../../assets/img/icons/marketing-smile.svg'


function Marketing({directionsData}) {
    const [count, setCount] = useState(0);
    const {t, i18n} = useTranslation()

    let block = null;

    switch (count) {
        case 0:
          block = <CloseIcon/>;
          break;
        case 1:
          block = <HeartIcon />;
          break;
        case 2:
          block = <SmileIcon/>;
          break;
        default:
          block = <CloseIcon />;
    }

    const toggleIcon = () => {
        if (count === 2) {
            setCount(count => 0);
        } else {
            setCount(count => count + 1);
        }
    }

    return (
        <div className="directions__marketing">
            <div className="directions__marketing-bar">
                <p>{t('directions.tabs.МАРКЕТИНГ.services')}</p>
                <button 
                    className="directions__marketing-shadow directions__marketing-button"
                    onClick={toggleIcon}    
                >
                    {block}
                </button>
            </div>
            <div className="directions__marketing-wrapper">
                <img src={gif} alt="Marketing" />
                <ul className='directions__marketing-list'>
                    {
                        directionsData[2].text.map((item, i) => (
                            <li className={`directions__marketing-${directionsData[2].className[i]}`} key={i}><span>- </span>{t(`directions.tabs.МАРКЕТИНГ.text.${i}`)}</li>
                        ))
                    }
                </ul>
            </div>
        </div>
    )
}

export default Marketing