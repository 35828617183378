import React, {useState, useRef, useEffect} from 'react'
import { Link } from 'react-router-dom';
import { Trans } from 'react-i18next';
import DirectionsCursor from '../DirectionsCursor';
import Accordion from '../Accordion';
import { directionsData } from '../../utils/content';
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation , Autoplay} from 'swiper';
import { aboutSlidesText } from '../../utils/content';
 
import "swiper/css";
import "swiper/css/pagination";


import { motion } from 'framer-motion';

import arrow from '../../assets/img/icons/arrow-button.svg'
import {ReactComponent as LeftArrow} from '../../assets/img/icons/case-arrow.svg'
import arrowLeft from '../../assets/img/icons/arrow-left.svg'
import arrowRight from '../../assets/img/icons/arrow-right.svg'
import { useTranslation } from 'react-i18next';

const AboutUs = ({routePush , setCurrentSlide, disableLink}) => {
	const {t} = useTranslation();
	const [currentTab, setCurrentTab] = useState({
		title: 'ВЕБ 3.0',
		id: 0
	});
	const aboutUsDirectionsRef = useRef(null);

	useEffect(() => {

		window.addEventListener('scroll', handleScroll);
	
		return () => {
			window.removeEventListener('scroll', handleScroll);

			const header = document.querySelector('.header');
			header.style.backgroundColor = '';
		};
	  }, []);

	const fintegrityHandle = (e) => {
		setCurrentSlide(0)
		routePush(e, true, '/cases/crypto-accounting')
	}
	const ezraHandle = (e) => {
		setCurrentSlide(1)
		routePush(e, true, '/cases/ezra')
	}

	const handleTab = (id, title) => {
		setCurrentTab({title, id})
	}

	const handleScroll = () => {
		const aboutUsDirectionsTop = aboutUsDirectionsRef.current.offsetTop;
		const scrollY = window.scrollY;
		const header = document.querySelector('.header')
		if (scrollY > aboutUsDirectionsTop) {
			header.style.backgroundColor = '#fffff0'
		} else {
			header.style.backgroundColor = 'unset'
		}
	};
	

    return (
		<motion.div className='aboutUs'
			initial={{  opacity: 0 }}
			animate={{  opacity: 1, transition: {duration: 1} }}
			exit={{ opacity: 0, transition: {duration: 1}}}
		>
			<picture>
				<source srcSet={require('../../assets/img/mobile-bg.webp')} type="image/webp"/>
				<img src={require('../../assets/img/mobile-bg.png')} alt='img' className="aboutUs__bg"/>
			</picture>

			<picture>
					<source srcSet={require('../../assets/img/about-us-background.webp')} type="image/webp"/>
					<img className='aboutUs__top-img' src={require('../../assets/img/about-us-background.png')} alt="Ukraine" />
			</picture>

			<div className="aboutUs__header">
				<Link to='/about' onClick={(e) => routePush(e, true, '/about')} className={`${disableLink && 'link-disable'}`}
				>
				<LeftArrow />
				</Link>
				<div className="header__line aboutUs__header-line aboutUs__header-line-small">
					<div className="header__line-block"></div>
				</div>
				<Link to='/about' onClick={(e) => routePush(e, true, '/about')} className={`${disableLink && 'link-disable'}`}
				>
					<div className="aboutUs__header-title">{t('about-us.line')}</div>
				</Link>
				<div className="header__line aboutUs__header-line aboutUs__header-line-big">
					<div className="header__line-block"></div>
				</div>
			</div>
			
			<div className="aboutUs__main">
				<div className="aboutUs__video-overlay"></div>
				<div className="aboutUs__sticky">
					<div className="aboutUs__sticky-wrapper">
						<div className="aboutUs__communication">
							<div className="aboutUs__communication-title">{t('about-us.contacts.title')}</div>
							<picture>
								<source srcSet={require('../../assets/img/ukraine.webp')} type="image/webp"/>
								<img className='aboutUs__communication-flag' src={require('../../assets/img/ukraine.png')} alt="Ukraine" />
							</picture>
							<div className='aboutUs__communication-adress'>{t('about-us.contacts.city')}</div>
							<div className="aboutUs__communication-line"></div>
							<a href="tel:+380502255535" className="aboutUs__communication-phone">+38 (050) 225-55-35</a>
							<a href="mailto:hello@uniqit.group" className="aboutUs__communication-mail">hello@uniqit.group</a>
							<div className="case__button case__button-contacts">
								<Link to='/contacts' onClick={(e) => routePush(e , true ,'/contacts')} className=' button button-black button--bestia'>
									<span>{t('about-us.contacts.button')}</span>
									<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M6.63673 7.7143L6.63673 6.04788L14.541 6.04788V13.9522H12.8746V8.89281L7.22599 14.5414L6.04747 13.3629L11.6961 7.7143L6.63673 7.7143Z" fill="white"/>
									</svg>
									<div className="button__bg"></div>
								</Link>
							</div>
						</div>
					</div>
					<div className="aboutUs__container">
						<div className="aboutUs__top">
							<div className="aboutUs__wrapper">
								<h1 className="aboutUs__main-title">
									<Trans i18nKey="about-us.title" components={{ 1: <br /> }} />
								</h1>
								<h4 className="aboutUs__subtitle">
									{t('about-us.subtitle')}
								</h4>
							</div>
						</div>
					</div>
					<div className="aboutUs__container">
						<div className="aboutUs__wrapper">
							<div className="aboutUs__line"></div>
							<div className="aboutUs__history">
								<div className="aboutUs__history-left">
									<h2 className="aboutUs__title">{t('about-us.history')}</h2>
									<div className="aboutUs__history-text">
										<Trans i18nKey="about-us.history-text" components={{ 1: <br /> , 2: <span/>}} />
									</div>
								</div>
								<div className="aboutUs__history-right">
									<div className="aboutUs__history-line">
										<div className="aboutUs__history-line-wrapper">
											<div className="aboutUs__history-year">2019</div>
											<div className="aboutUs__history-middle">
												<div className="aboutUs__history-circle">
													<div></div>
												</div>
												<div className="aboutUs__history-middle-line"></div>
												<div className="aboutUs__history-middle-topLine"></div>
											</div>
										</div>
										<div className="aboutUs__history-descr">{t('about-us.2019')}</div>
									</div>
									<div className="aboutUs__history-line">
										<div className="aboutUs__history-line-wrapper">
											<div className="aboutUs__history-year">2020</div>
											<div className="aboutUs__history-middle">
												<div className="aboutUs__history-circle">
													<div></div>
												</div>
												<div className="aboutUs__history-middle-line"></div>
											</div>
										</div>
										<div className="aboutUs__history-descr">{t('about-us.2020')}</div>
									</div>
									<div className="aboutUs__history-line">
										<div className="aboutUs__history-line-wrapper">
											<div className="aboutUs__history-year">2021</div>
											<div className="aboutUs__history-middle">
												<div className="aboutUs__history-circle">
													<div></div>
												</div>
												<div className="aboutUs__history-middle-line"></div>
											</div>
										</div>
										<div className="aboutUs__history-descr">{t('about-us.2021.text')} <br /> <Link to='/cases/ezra'
										onClick={(e) => ezraHandle(e)}>{t('about-us.2021.link')}</Link>
										</div>
									</div>
									<div className="aboutUs__history-line">
										<div className="aboutUs__history-line-wrapper">
											<div className="aboutUs__history-year">2022</div>
											<div className="aboutUs__history-middle">
												<div className="aboutUs__history-circle">
													<div></div>
												</div>
												<div className="aboutUs__history-middle-line"></div>
											</div>
										</div>
										<div className="aboutUs__history-descr">
											{/* {t('about-us.2022.text')}  */}
											<Trans i18nKey='about-us.2022.text' components={{ 1: <br /> }} />
											<br />
										<Link to='/cases/crypto-processing' onClick={(e) => fintegrityHandle(e)}>{t('about-us.2022.link')}</Link>
										</div>
									</div>
									<div className="aboutUs__history-line">
										<div className="aboutUs__history-line-wrapper">
											<div className="aboutUs__history-year">2023</div>
											<div className="aboutUs__history-middle">
												<div className="aboutUs__history-circle aboutUs__history-circle-green">
													<div></div>
												</div>
												<div className="aboutUs__history-middle-line aboutUs__history-middle-lineLast"></div>
												<div className="aboutUs__history-middle-bottomLine"></div>
											</div>
										</div>
										<div className="aboutUs__history-descr">
											<Trans i18nKey='about-us.2023.text' components={{ 1: <br /> }} />
											<br/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div ref={aboutUsDirectionsRef} className="aboutUs__directions">
						<div className="aboutUs__container fluid">
							<div className="aboutUs__wrapper">
								<div className="aboutUs__container">
									<h2 className="aboutUs__title">{t('about-us.direction')}</h2>
								</div>
								<div className="aboutUs__directions-wrapper">
									<div className="aboutUs__directions-block">
										{
											directionsData.map((item, i) => (
												<div key={i} 
													className={currentTab.title === item.title ? 'directions__block-item directions__block-item-active' : 'directions__block-item'}
													onClick={() => handleTab(i, item.title)}
												>
													<div className="directions__block-top" 
													
													>
														<div className="directions__block-top-title">{t(`directions.tabs.${item.title}.title`)}</div>
														<img src={arrow} alt="Arrow" />
													</div>

												</div>
											))
										}
										<DirectionsCursor cursorWrapper={'.aboutUs__directions-block'}/>
									</div>
									<div className="aboutUs__directions-content">
										<ul className='directions__block-list'>
											{
												directionsData[currentTab.id].textMobile ? 
												directionsData[currentTab.id].textMobile.map((item, i) => (
													<li key={i}>
														<Trans i18nKey={`directions.tabs.${directionsData[currentTab.id].title}.text.${i}`} components={{ 1: <b/> }} />
													</li>
												)) : 
												directionsData[currentTab.id].text.map((item, i) => (
													<li key={i}>
														<Trans i18nKey={`directions.tabs.${directionsData[currentTab.id].title}.text.${i}`} components={{ 1: <b/> }} />
													</li>
												))
											}
										</ul>
									</div>
									<div className="directions__block-accordion">
										{
											directionsData.map((item, i) => (
												<Accordion key={i} text={item.text} textMobile={item?.textMobile} title={item.title} />
											))
										}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="aboutUs__charity">
					<div className='aboutUs__charity-left'>
						<div className="aboutUs__charity-title aboutUs__title">
							{t('about-us.charity')}
							<picture>
								<source srcSet={require('../../assets/img/Ukraine-flag.webp')} type="image/webp"/>
								<img src={require('../../assets/img/Ukraine-flag.png')} alt="Прапор" />
							</picture>
						</div>
						<div className="aboutUs__charity-text">
							<Trans i18nKey="about-us.charity-text" components={{ 1: <br />}} />	
						</div>
					</div>
					<Swiper
						loop
						autoplay={{
							delay: 5500,
							disableOnInteraction: false,
						  }}
						spaceBetween={0}
						slidesPerView={2}
						modules={[Navigation, Autoplay]}
						navigation={{
							nextEl: ".aboutUs__charity-arrow-right",
							prevEl: ".aboutUs__charity-arrow-left",
						}}
						breakpoints={{
							320 : {
								slidesPerView : 1
							},
							576 : {
								slidesPerView : 2
							},
							1199 : {
								slidesPerView : 1
							},
							1600 : {
								slidesPerView : 2
							},
							2560 : {
								slidesPerView : 3
							}
						}}
					>
						{
							aboutSlidesText.map((text, i) => (
								<SwiperSlide key={i}>
									<div className="aboutUs__charity-descr">
										<Trans i18nKey={`about-us.charity-slide.${i}`} components={{ 1: <br />}} />	
									</div>
									<picture>
										<source srcSet={require(`../../assets/img/about-slide-${i + 1}.webp`)} type="image/webp"/>
										<img src={require(`../../assets/img/about-slide-${i + 1}.png`)} alt="Благодійність" />
									</picture>
								</SwiperSlide>
							))
						}
						<div className="aboutUs__charity-arrow aboutUs__charity-arrow-left">
							<img src={arrowLeft} alt="Arrow" />
						</div>
						<div className="aboutUs__charity-arrow aboutUs__charity-arrow-right">
							<img src={arrowRight}  alt="Arrow" />
						</div>
					</Swiper>
				</div>
				<div className="aboutUs__container">
					<div className="aboutUs__wrapper">
						<div className="aboutUs__line"></div>
						<div className="aboutUs__contact">
							<h3 className="aboutUs__title">{t('page-contact.title')}</h3>
							<p className="aboutUs__contact-subtitle">
								{t('page-contact.start')} <Link to='/contacts'
									onClick={(e) => routePush(e, true ,'/contacts')}
								>{t('page-contact.phone')}</Link> {t('page-contact.text')} <a className='aboutUs__contact-link' href="mailto:hello@uniqit.group">hello@uniqit.group</a></p>
							<div className="case__button aboutUs__contact-button">
								<Link to='/contacts' onClick={(e) => routePush(e, true ,'/contacts')} className='button button-black button--bestia'>
									<span>{t('about-us.button')}</span>
									<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M6.63673 7.7143L6.63673 6.04788L14.541 6.04788V13.9522H12.8746V8.89281L7.22599 14.5414L6.04747 13.3629L11.6961 7.7143L6.63673 7.7143Z" fill="white"/>
										</svg>
									<div className="button__bg"></div>
								</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
		</motion.div>
  )
}

export default AboutUs;