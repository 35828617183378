import React, {useState, useEffect} from 'react'
import { useLocation } from 'react-router-dom'
import LinksBlock from './LinksBlock'
import { useTranslation } from 'react-i18next'


import {ReactComponent as Logo} from '../assets/img/logo.svg'

import HeaderLanguageToggle from './HeaderLanguageToggle'


const Header = ({animate = true, disableHeaderAnimation}) => {
    const [isActive, setIsActive] = useState(false);
    const {pathname} = useLocation();

    const {t} = useTranslation();

    const handleToggle = () => {
        if (window.innerWidth < 767) {
            setIsActive(!isActive);
        }
    }

    return (
        <header className={pathname === '/directions' || pathname === '/contacts' ? 'header header-black' : 'header'}
        >
            <div className="header__wrapper">
                <div  className='header__logo'>
                    <Logo/>
                </div>
                <div  className='header__logo header__logo-mobile'>
                    <Logo/>
                </div>
                <div className="header__line">
                    <div className="header__line-block"></div>
                </div>
                <nav className='header__nav'>
                    <ul className="header__nav-links">
                        <li className='header__nav-link'>
                            <div to='about'>{t('header.about')}</div>
                        </li>
                        <li className='header__nav-link'>
                            <div to='cases'>{t('header.cases')}</div>
                        </li>
                        <li className='header__nav-link'>
                            <div to='directions'>{t('header.directions')}</div>
                        </li>
                        <li className='header__nav-link'>
                            <div to='contacts' className='button button-white button--bestia'>
                                <span>{t('header.contacts')}</span>
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6.63673 7.7143L6.63673 6.04788L14.541 6.04788V13.9522H12.8746V8.89281L7.22599 14.5414L6.04747 13.3629L11.6961 7.7143L6.63673 7.7143Z" fill="white"/>
                                </svg>
                                <div className="button__bg"></div>
                            </div>
                        </li>
                    </ul>
                </nav>
                <nav className={isActive ? 'header__nav header__nav-mobile header__nav-mobile-active' : 'header__nav header__nav-mobile'}>
                    <ul className="header__nav-links">
                        <li className='header__nav-link'>
                            <div onClick={handleToggle} to='/'>Головна</div>
                        </li>
                        <li className='header__nav-link'>
                            <div onClick={handleToggle} to='about'>Про нас</div>
                        </li>
                        <li className='header__nav-link'>
                            <div onClick={handleToggle} to='cases'>Кейси</div>
                        </li>
                        <li className='header__nav-link'>
                            <div onClick={handleToggle} to='directions'>Напрямки</div>
                        </li>
                        <li className='header__nav-link '>
                            <div onClick={handleToggle} to='contacts'>Контакти</div>
                        </li>
                    </ul>
                    <div className="header__mobile-bottom">
                        <div className="header__mobile-bottom-links">
                            <LinksBlock/>
                        </div>
                        <HeaderLanguageToggle/>
                    </div>
                </nav>
                <div className="header__line header__line-small">
                    <div className="header__line-block"></div>
                </div>
                <HeaderLanguageToggle/>
                <div className={isActive ? 'header__hamburger header__hamburger_active' : 'header__hamburger'}
                         onClick={handleToggle}>
                        <div className="header__hamburger-line header__hamburger-line_1"></div>
                        <div className="header__hamburger-line header__hamburger-line_2"></div>
                        <div className="header__hamburger-line header__hamburger-line_3"></div>
                </div>
            </div>
        </header>
    )
}

export default Header