import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';


function Web({videoIsLoop}) {
  let charList = '';
  let  linesSymbols = [];

  const  {i18n} = useTranslation();
  const lang = i18n.language;
  

  if (lang.startsWith('en')) {
    linesSymbols = [
      '-Development and Support',
      ' of smart contracts.',
      '-Interaction with smart through',
      ' the front-end and back-end.',
      "-Creation of blockchain-based",
      " services.",
      '-Building NFT collections.',
      '-Testing and vulnerability ',
      " assessment of smart contracts."
    ];
    charList = [' ', '.', '-','A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']
  } else {
    linesSymbols = [
      '-Розробка та підтримка',
      ' смарт-контрактів.',
      '-Взаємодія зі смарт-контрактами',
      ' через front-end та back-end.',
      '-Розробка сервісів',
      ' на базі блокчейну.',
      '-Створення NFT колекцій.',
      '-Тестування та пошук вразливостей у',
      ' смарт-контрактаХ.'
    ];
    charList = [
      ' ', '.', '-', 'А', 'Б', 'В', 'Г', 'Ґ', 'Д', 'Е', 'Є', 'Ж', 'З', 'И', 'І', 'Ї', 'Й', 'К', 'Л', 'М', 'Н', 'О', 'П', 'Р', 'С', 'Т', 'У', 'Ф', 'Х', 'Ц', 'Ч', 'Ш', 'Щ', 'Ь', 'Ю', 'Я', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z',
    ]
  }

  useEffect(() => {
    const switchChar = (element, char, n) => {
      const current = element.dataset.value;
      let start = 0;

      for (let i = 0; i < charList.length; i++) {
        if (charList[i] === current?.toUpperCase()) {
          start = i;
          break;
        }
      }

      let complete = false;
      let translateY = 0;

      for (let i = start; i < charList.length; i++) {
        if (charList[i] === char?.toUpperCase()) {
          complete = true;
          break;
        }

        translateY -= 63;
        element.dataset.value = char;
        element.querySelector('ul').style.transform = `translateY(${translateY}px)`;
      }

      if (!complete) {
        translateY = 0;
        element.querySelector('ul').style.transform = 'translateY(0px)';
        start = 0;

        for (let i = start; i < charList.length; i++) {
          if (charList[i] === char) {
            complete = true;
            break;
          }

          translateY -= 63;
          element.dataset.value = char;
          element.querySelector('ul').style.transform = `translateY(${translateY}px)`;
        }
      }
    };

    let timeout = null;

    const resetAnimation = () => {
      const webLines = document.querySelectorAll('.directions__web-line');
  
      webLines.forEach((line) => {
        const webItems = line.querySelectorAll('.directions__web-item');
    
        webItems.forEach((item) => {
          item.querySelector('ul').style.transform = 'translateY(0)';
          item.dataset.value = '';
        });
      });
    };
  
    // Сброс анимации и значений при смене языка
    resetAnimation();

    if (videoIsLoop) {
       timeout = setTimeout(() => {
        const webLines = document.querySelectorAll('.directions__web-line');
  
        webLines.forEach((line, index) => {
          line.dataset.value = ' ';
  
          const lineSymbol = linesSymbols[index];
          const lineSymbolEx = lineSymbol.split('');
  
          const webItems = line.querySelectorAll('.directions__web-item');
  
          webItems.forEach((item, k) => {
            if (k + 1 > lineSymbolEx.length) {
              return;
            }
  
            switchChar(item, lineSymbolEx[k], k);
          });
        });
      }, 500);
    }

    return () => {
      clearTimeout(timeout);
      resetAnimation();
    };
  }, [videoIsLoop, lang]);

  return (
    <div className="directions__web">
      {linesSymbols.map((lineSymbol, i) => (
        <div key={i} className="directions__web-line">
          {[...Array(36)].map((_, index) => (
            <div key={index} className="directions__web-item">
              <ul className="directions__web-list">
                {charList.map((char, j) => (
                  <li key={j}>{char}</li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
}

export default Web;
