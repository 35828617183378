import {useState} from "react";

const ToggleSwitch = ({ className, checked = false }) => {
    const [isChecked, setChecked] = useState(checked);
    let switchClasses = 'switch ';
    if (className) {
        switchClasses += className;
    }
    const handleToggle = (e) => {
        setChecked(e.target.checked)
    }
    return (
        <label className={switchClasses}>
            <input type="checkbox" onChange={handleToggle} checked={isChecked} />
            <span className="slider">
                <span className="slider__on">On</span>
                <span className="slider__off">Off</span>
            </span>
        </label>
    )
}

export default ToggleSwitch;