import React , {useState, useEffect} from 'react'
import CaseHeader from '../CaseHeader'
import { Trans } from 'react-i18next'
import { scrollToTop } from '../../utils/helpersFunction'
import { cryptoAccountingContent } from '../../utils/content'
import Cases from './Cases'
import { Link } from 'react-router-dom'
import { motion , cubicBezier } from 'framer-motion'

// import { caseImg } from '../../utils/content'


import {ReactComponent as LeftArrow} from '../../assets/img/icons/case-arrow.svg'
import { useTranslation } from 'react-i18next'


function SingleCase({handleAnimationDisable, currentSlide, isMobile ,routePush}) {

    const {t} = useTranslation();

    const [fadeOut, setFadeOut] = useState(false);

    useEffect(() => {
        scrollToTop()
    }, [])


    const containerBezier = cubicBezier(.65, 0, .15, 1);
    const wrapperBezier = cubicBezier(.5,0,0,1)

    const handleLinkBack = (e , route) => {
        handleAnimationDisable()
        routePush(e, true, route)
    }

    const handleLinkContact = (e , route, animateDisable) => {
        if (animateDisable) {
            setFadeOut(true);
        }
        routePush(e, true, route)
    }
    

    const skewVariants = {
        initial: {scale: 1, skew: 0, WebkitFilter: 'blur(0)'},
        animate: {scale: .8, skew: '-5deg', WebkitFilter: 'blur(1.5px)', transition: {duration: 2.5, ease: wrapperBezier}}, 
        exit: {scale: 1, skew: 0, WebkitFilter: 'blur(0px)', transition: {duration: 2.5, ease: wrapperBezier}}
    }

    const translateVariants = {
        initial : {x: 0},
        animate : {x: `${isMobile ? '100vw' : '76vw'}`, transition: {delay: .8, duration: 2, ease: containerBezier}},
        exit:{x: 0, transition: {delay: .8, duration: 2, ease: containerBezier}}
    }

    return (

        <motion.div className='case'
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { duration: 0 } }}
            exit={fadeOut ? { opacity: 0, transition: { duration: 1} } : {}}
        >
            <motion.div className="case__right-animation"
                variants={!fadeOut ? translateVariants : {}}
                initial="initial"
                animate="animate"
                exit="exit"
            >
                <motion.div className='case__back'
                    variants={!fadeOut ? skewVariants : {}}
                    initial="initial"
                    animate="animate"
                    exit="exit"
                >
                    <Link to='/cases' 
                           onClick={e => handleLinkBack(e, '/cases')}
                    >
                    </Link>
                </motion.div>
                <motion.div className="case__right-wrapper"
                    variants={!fadeOut ? skewVariants : {}}
                    initial="initial"
                    animate="animate"
                    exit="exit"
                >
                        <CaseHeader/>
                        <Cases animate={false} exitDisabled={true} currentSlide={currentSlide}/>
                </motion.div>
            </motion.div>
            <motion.div className="case__main"
                initial={{opacity: 0 , x: '-100%'}}
                animate={{opacity: 1, x: 0, transition: {duration: 1, delay: 1.5}}}
                exit={!fadeOut ? {opacity: 0 , x: '-100%' , transition: {duration: 1}} : {}}
            >
                <picture>
                    <source srcSet={require('../../assets/img/mobile-bg.webp')} type="image/webp"/>
                    <img src={require('../../assets/img/mobile-bg.png')} alt='img' className="case__bg"></img>
                </picture>
                <div className="case__wrapper">
                    <div className="case__header">
                        <Link to={'/cases'}
                            onClick={e => handleLinkBack(e, '/cases')}
                        >
                            <LeftArrow/>
                        </Link>
                        <div className="header__line case__header-line case__header-line-small">
                            <div className="header__line-block"></div>
                        </div>
                        <Link to={'/cases'}
                            onClick={e => handleLinkBack(e, '/cases')}
                        >
                            <div className="case__header-title">{t('crypto.case')} / Crypto Processing</div>
                        </Link>
                        <div className="header__line case__header-line">
                            <div className="header__line-block"></div>
                        </div>
                    </div>
                    <div className="case__title-wrapper">
                        <h1 className="case__title">Crypto Processing</h1>
                        <div  className="cases__slide-link cases__slide-link-coming link link--iocaste">
                            coming soon
                            <svg className="link__graphic link__graphic--slide" width="300%" height="100%" viewBox="0 0 1200 60" preserveAspectRatio="none">
                                <path d="M0,56.5c0,0,298.666,0,399.333,0C448.336,56.5,513.994,46,597,46c77.327,0,135,10.5,200.999,10.5c95.996,0,402.001,0,402.001,0"></path>
                            </svg>
                        </div>
                    </div>
                    <div className="case__technology">
                        <div className="case__technology-stack">
                            <div className="case__technology-block">{t('crypto.stack.1')}</div>
                            <div className="case__technology-block">{t('crypto.stack.0')}</div>
                        </div>
                        <div className="case__technology-year">
                            <div className="case__technology-block">2022</div>
                        </div>
                    </div>
                    <picture>
                        <source srcSet={require('../../assets/img/cases-1.webp')} type="image/webp"/>
                        <img src={require('../../assets/img/cases-1.png')} alt="Crypto concept" className="case__img-main case__img-border" />
                    </picture>
                    <p className="case__text mb-32">
                        <span>Crypto Processing</span> - {t('crypto.descr')}
                    </p>
                
                    <div className="case__line"></div>
                    <h3 className='case__subtitle'>{t('crypto.title')}</h3>

                    <div className="fintegrity">
                        <p className="case__text mt-48">
                            <Trans i18nKey="crypto.business" components={{ 1: <b /> }} />
                        </p>
                        <div className="fintegrity__list mt-48">
                            <div className="fintegrity__list-title case__text"><b>{t('crypto.goal')}</b></div>
                            <ul>
                                {
                                    cryptoAccountingContent.list_1.map((item, i) => (
                                        <li key={i} className='case__text'>{t(`crypto.goals-1.${i}`)}</li>
                                    ))
                                }
                            </ul>
                        </div>
                        <div className="case__line fintegrity__line"></div>
                        <p className="case__text">
                            <Trans i18nKey="crypto.client" components={{ 1: <b /> }} />
                        </p>
                        <div className="fintegrity__list mt-48">
                            <div className="fintegrity__list-title case__text"><b>{t('crypto.goal')}</b></div>
                            <ul>
                                {
                                    cryptoAccountingContent.list_2.map((item, i) => (
                                        <li key={i} className='case__text'>
                                            <Trans i18nKey={`crypto.goals-2.${i}`} components={{ 1: <br /> }} />
                                            {/* {t(`crypto.goals-2.${i}`)} */}
                                        </li>
                                    ))
                                }
                            </ul>
                        </div>
                        <div className="case__line fintegrity__line"></div>
                        <p className="case__text">
                            <Trans i18nKey="crypto.founder" components={{ 1: <b /> }} />
                        </p>
                        <div className="fintegrity__list mt-48">
                            <div className="fintegrity__list-title case__text"><b>{t('crypto.goal')}</b></div>
                            <ul>
                                {
                                    cryptoAccountingContent.list_3.map((item, i) => (
                                        <li key={i} className='case__text'>{t(`crypto.goals-3.${i}`)}</li>
                                    ))
                                }
                            </ul>
                        </div>
                        <div className="case__line fintegrity__line"></div>

                        <div className="fintegrity__list">
                            <h3 className='case__subtitle mb-24'>{t('crypto.keys')}</h3>
                            <ul>
                                {
                                    cryptoAccountingContent.list_4.map((item, i) => (
                                        <li key={i} className='case__text'>{t(`crypto.goals-4.${i}`)}</li>
                                    ))
                                }
                            </ul>
                        </div>
                        <div className="case__line fintegrity__line"></div>
                    </div>

                    <div className="case__subtitle mb-24">{t('page-contact.title')}</div>
                    <p className="case__text case__connect">{t('page-contact.start')} <Link to='/contacts'
                        onClick={(e) => handleLinkContact(e ,'/contacts', true)}
                        >{t('page-contact.phone')}</Link> {t('page-contact.text')} <a className='case__mail' href='mailto:hello@uniqit.group'>hello@uniqit.group</a>
                    </p>
                    <div className="case__button case__button-contacts">
                        <Link to='/contacts' onClick={(e) => handleLinkContact(e, '/contacts', true)} className='button button-black button--bestia'>
                            <span>{t('page-contact.button')}</span>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6.63673 7.7143L6.63673 6.04788L14.541 6.04788V13.9522H12.8746V8.89281L7.22599 14.5414L6.04747 13.3629L11.6961 7.7143L6.63673 7.7143Z" fill="white"/>
                                </svg>
                            <div className="button__bg"></div>
                        </Link>
                    </div>
                </div>
            </motion.div>
        </motion.div>
    )
}

export default SingleCase