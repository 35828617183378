import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

function Fintech() {
  const [glitchInitialized, setGlitchInitialized] = useState(false);
  const {t} = useTranslation();

  useEffect(() => {
    const chars = "₴$€£¥";

    class Glitch {
      constructor(selector, index, numberOfGlitchedLetter, timeGlitch, timePerLetter, timeBetweenGlitch) {
        this.selector = selector;
        this.index = index;
        this.numberOfGlitchedLetter = numberOfGlitchedLetter;
        this.innerText = '';
        this.charArray = [];
        this.charIndex = [];
        this.timeGlitch = timeGlitch;
        this.timeBetweenGlitch = timeBetweenGlitch;
        this.timePerLetter = timePerLetter;
        this.maxCount = Math.floor(this.timeGlitch / this.timePerLetter);
        this.count = 0;
      }

      init() {
        this.innerText = this.selector.innerText;
        this.charArray = this.innerText.split("");
        if (this.numberOfGlitchedLetter === undefined || this.numberOfGlitchedLetter > this.innerText.length) {
          this.numberOfGlitchedLetter = this.innerText.length;
        }
        this.defineCharIndexToRandomize();
      }

      defineCharIndexToRandomize() {
        this.charIndex = [];
        for (let i = 0; i < this.numberOfGlitchedLetter; i++) {
          let randCharIndex = Math.floor(Math.random() * this.charArray.length);
          this.charIndex.push(randCharIndex);
        }
      }

      randomize() {
        let randomString = Array.from(this.charArray);
        for (let i = 0; i < this.numberOfGlitchedLetter; i++) {
          let randIndex = Math.floor(Math.random() * chars.length);
          let randCharIndex = this.charIndex[i];
          if (randomString[randCharIndex] !== ' ') {
            randomString[randCharIndex] = chars[randIndex];
          }
        }
        this.selector.innerText = randomString.join("");
      }

      update(interval) {
        if (this.count >= this.maxCount - 1) {
          this.selector.innerText = this.innerText;
          this.defineCharIndexToRandomize();
          let ctx = this;
          // let wait = setTimeout(function () {
          //   ctx.count = 0;
          // }, this.timeBetweenGlitch);
        } else {
          this.randomize();
          this.count++;
        }
      }

      glitch() {
        let ctx = this;
        let interval = setInterval(function () {
          ctx.update(this);
        }, this.timePerLetter);
      }
    }

    const initAllGlitch = () => {
      const arrayElements = document.querySelectorAll(".glitch-list-item");
      const glitchArray = [];
      for (let i = 0; i < arrayElements.length; i++) {
        let selector = arrayElements[i];
        let randLetterNumber = 8;
        let randGlitchTime = 500 + Math.floor(Math.random() * 2500);
        let randGlitchPauseTime = 2000 + Math.floor(Math.random() * 4000);
        let glitch = new Glitch(selector, i, randLetterNumber, 2000, 65, randGlitchPauseTime);
        glitch.init();
        glitchArray.push(glitch);
      }

      const update = () => {
        for (let i = 0; i < glitchArray.length; i++) {
          let glitch = glitchArray[i];
          glitch.glitch();
        }
      }

      update();
      setGlitchInitialized(true);
    };

    if (!glitchInitialized) {
      initAllGlitch();
    }
  }, [glitchInitialized]);

  return (
    <div className="directions__fintech">
      <div className="directions__fintech-wrapper">
        <div className="directions__fintech-title">
          {t('directions.tabs.ФІНТЕХ.services')}
        </div>
        <ul>
          <li className="glitch-list-item">-{t('directions.tabs.ФІНТЕХ.text.0')}</li>
          <li className="glitch-list-item">-{t('directions.tabs.ФІНТЕХ.text.1')}</li>
        </ul>
      </div>
    </div>
  );
}

export default Fintech;
