import React, { useState, useRef, useEffect } from 'react';
import Cookies from 'js-cookie';
import { Formik, Form, Field, ErrorMessage } from 'formik'; 
import { formDataModel } from '../../utils/content';
import emailjs from '@emailjs/browser';
import  useFormSchema  from './useFormSchema';
import FormError from './FormError';
import FormCookieError from './FormCookieError';
import { useTranslation } from 'react-i18next';
const ContactForm = ({setStatus, currentTab, routePush, setCookiesVisible, formData, setFormData, setCookieError, cookieError}) => {

    const form = useRef();
    const {t} = useTranslation();

    const cookie = Cookies.get('is_cookies_accept');

    const {validationSchema} = useFormSchema();

    useEffect(() => {
        if (cookie) {
            setCookieError(false);
        } 
    }, [cookie])

    useEffect(() => {
        if (currentTab.choosed) {
            setFormData(data => (
                {
                    ...data,
                    stack : [currentTab.direction]
                }
            ))
        }
    }, [])

    const handleRadioChange = (event) => {
        const value = event.target.value
        if (formData.stack.includes(value)) {
            const filteredState = formData.stack.filter(item => item !== value);
            setFormData(data => (
                {
                    ...data,
                    stack : filteredState
                }
            ))
        } else {
            setFormData(data => (
                {
                    ...data,
                    stack : [...data.stack, value]
                }
            ))
        }
    };

    const handlePhoneChange = (event,  setFieldValue) => {
        let value = event.target.value;
        value = value.replace(/[^0-9+()-]/g, ''); // Remove all characters except digits and "+"

        setFormData((data) => (
            {
                ...data,
                phone : value
            }
        ));

        setFieldValue('phone', value);
    };

    const handleChange = (e, setFieldValue) => {
        const name = e.target.name;
        const value = e.target.value;
        setFormData((data) => (
            {
                ...data,
                [name] : value
            }
        ));
        setFieldValue(name, value);
    }


    const sendEmail = (e) => {
        if (!cookie) {
            setCookieError(true);
            return;
        }
        // e.preventDefault();
        setStatus({isSubmitting: true, sendingStatus: 'loading'})
        emailjs.sendForm(process.env.REACT_APP_MAIL_SERVICE_KEY, process.env.REACT_APP_TEMPLATE_KEY, form.current, process.env.REACT_APP_USER_KEY)

        .then((result) => {
            setStatus({isSubmitting: true, sendingStatus: 'done'})
        }, (error) => {
            setStatus({isSubmitting: true, sendingStatus: 'error'})
        }).finally(() => {
            setTimeout(() => {
                form.current.reset();
                setFormData(formDataModel)
                setStatus({isSubmitting: false, sendingStatus: 'idle'})
            }, 3000)
        })
    };

    return (
        <Formik
            initialValues={formData}
            validationSchema={validationSchema}
            onSubmit={values => sendEmail(values)}
        >
           {({errors, touched, isValid, dirty, values, setFieldValue}) => (
             <Form  ref={form}  className="contacts__form">
                <h1 className="contacts__title">{t('contacts.title')}</h1>
                <div className="contacts__form-inputs">
                    <div className="contacts__form-input">
                        <ErrorMessage name='name' >{msg => <FormError msg={msg}/>}</ErrorMessage>
                        <div className="contacts__form-input-wrapper">
                            <Field 
                                name="name" 
                                type="text" 
                                value={formData.name}
                                onChange={e => handleChange(e, setFieldValue)}
                                placeholder={t('contacts.name')} 
                                className={errors.name && touched.name && 'contacts__form-input-error'}/>
                        </div>
                    </div>
                    <div className="contacts__form-input">
                        <ErrorMessage name='company' >{msg => <FormError msg={msg}/>}</ErrorMessage>
                        <div className="contacts__form-input-wrapper">
                            <Field
                                name="company"
                                value={formData.company}
                                type="text"
                                onChange={e => handleChange(e, setFieldValue)}
                                placeholder={t('contacts.company')}
                                className={errors.company && touched.company && 'contacts__form-input-error'}
                            />
                        </div>
                    </div>
                </div>
                <div className="contacts__form-inputs contacts__form-inputs-second">
                    <div className="contacts__form-input">
                        <ErrorMessage name='phone' >{msg => <FormError msg={msg}/>}</ErrorMessage>
                        <div className="contacts__form-input-wrapper">
                        <Field 
                            name="phone" 
                            type="text" 
                            placeholder={t('contacts.phone')}
                            value={formData.phone}
                            // onKeyUp={handlePhoneChange}
                            onChange={e => handlePhoneChange(e, setFieldValue)}
                            className={errors.phone && touched.phone && 'contacts__form-input-error'}/>
                        </div>
                    </div>
                    <div className="contacts__form-input">
                        <ErrorMessage name='email' >{msg => <FormError msg={msg}/>}</ErrorMessage>
                        <div className="contacts__form-input-wrapper">
                            <Field name="email" 
                            type="email" 
                            value={formData.email}
                            onChange={e => handleChange(e, setFieldValue)}
                            placeholder={t('contacts.email')} 
                            className={errors.email && touched.email && 'contacts__form-input-error'}/>
                        </div>
                    </div>
                </div>
                <div className="contacts__form-directions">
                    {t('contacts.direction')}
                </div>
                <div className="contacts__form-checkboxs">
                    <div className="contacts__form-checkbox">
                        <Field
                            name="stack"
                            checked={formData.stack.includes('ВЕБ 3.0')}
                            type="checkbox"
                            id="web"
                            value="ВЕБ 3.0"
                            onChange={handleRadioChange}
                        />
                        <label htmlFor="web" className="contacts__form-checkbox">
                        {t('contacts.checkbox.web')}
                        </label>
                    </div>
                    <div className="contacts__form-checkbox">
                        <Field
                            name="stack"
                            checked={formData.stack.includes('ФІНТЕХ')}
                            type="checkbox"
                            id="fintech"
                            value="ФІНТЕХ"
                            onChange={handleRadioChange}
                        />
                        <label
                            htmlFor="fintech"
                            className="contacts__form-checkbox"
                        >
                            {t('contacts.checkbox.fintech')}
                        </label>
                    </div>
                    <div className="contacts__form-checkbox">
                        <Field
                            name="stack"
                            checked={formData.stack.includes('МАРКЕТИНГ')}
                            type="checkbox"
                            id="marketing"
                            value="МАРКЕТИНГ"
                            onChange={handleRadioChange}
                        />
                        <label
                            htmlFor="marketing"
                            className="contacts__form-checkbox"
                        >
                            {t('contacts.checkbox.marketing')}
                        </label>
                    </div>
                    <div className="contacts__form-checkbox">
                        <Field
                            name="stack"
                            checked={formData.stack.includes('IT АУТСОРС')}
                            type="checkbox"
                            id="autsors"
                            value="IT АУТСОРС"
                            onChange={handleRadioChange}
                        />
                        <label
                            htmlFor="autsors"
                            className="contacts__form-checkbox"
                        >
                            {t('contacts.checkbox.it')}
                        </label>
                    </div>
                </div>
                <div className="contacts__form-directions">
                    {t('contacts.task')}
                </div>
                <div className="contacts__form-inputs contacts__form-inputs-bottom">
                <div className="contacts__form-input contacts__form-input-bottom">
                    <ErrorMessage name='task' >{msg => <FormError msg={msg}/>}</ErrorMessage>
                    <div className="contacts__form-input-wrapper">
                        <Field
                            name="task"
                            value={formData.task}
                            onChange={e => handleChange(e, setFieldValue)}
                            type="text"
                            placeholder={t('contacts.task-text')}
                            className={errors.task && touched.task && 'contacts__form-input-error'}
                        />
                    </div>
                </div>
                </div>
                {
                    cookieError && <FormCookieError routePush={routePush} setCookiesVisible={setCookiesVisible}/>
                }
                <button
                    disabled={!(isValid) || cookieError}
                    // style={{marginTop: cookieError && "16px"}}
                    className="contacts__form-button button button-black button--bestia"
                    type="submit"
                >
                    <span>{t('contacts.button')}</span>
                    <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M6.63673 7.7143L6.63673 6.04788L14.541 6.04788V13.9522H12.8746V8.89281L7.22599 14.5414L6.04747 13.3629L11.6961 7.7143L6.63673 7.7143Z"
                            fill="white"
                        />
                    </svg>
                    <div className="button__bg"></div>
                </button>
            </Form>
           )}
        </Formik>
    );
};

export default ContactForm;
