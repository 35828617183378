import React from 'react'

import {ReactComponent as Facebook} from '../assets/img/icons/fb.svg'
import  {ReactComponent as Linkedin} from '../assets/img/icons/linkedin.svg'
import {ReactComponent as Telegram} from '../assets/img/icons/telega.svg'

function LinksBlock() {
    return (
        <div className="links">
            <a href="https://www.facebook.com/" target='_blanc'>
                <Facebook />
            </a>
            <a href="https://www.linkedin.com/" target='_blanc'>
                <Linkedin />
            </a>
            <a href="https://web.telegram.org/" target='_blanc'>
                <Telegram />
            </a>
        </div>
    )
}

export default LinksBlock