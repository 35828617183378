import React, { useEffect, useRef } from 'react'
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';

import {ReactComponent as ArrowRight} from '../assets/img/icons/arrow-right.svg'

const TermsLayout = ({children, data, cookieHeigth}) => {
    const {t} = useTranslation();

    const privacyNavRef = useRef(null);
    const privacyNavContainerRef = useRef(null);
    const privacyNavLeftBtnRef = useRef(null);
    const privacyNavRightBtnRef = useRef(null);

    
  
    useEffect(() => {
      const privacyNav = privacyNavRef.current;
      const privacyNavContainer = privacyNavContainerRef.current;
      const privacyNavLeftBtn = privacyNavLeftBtnRef.current;
      const privacyNavRightBtn = privacyNavRightBtnRef.current;


      const checkWindowWidth = () => {
 
        const windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        const privacyScrollWidth = privacyNav.scrollWidth;


        privacyNavRightBtn.classList.add('terms__nav-btn-active');
        privacyNavLeftBtn.classList.add('terms__nav-btn-active');

        if (privacyNav.scrollLeft <= 10) {
          privacyNavLeftBtn.classList.remove('terms__nav-btn-active');
        }
  
        if (privacyNav.clientWidth + privacyNav.scrollLeft + 10 > privacyNav.scrollWidth) {
          privacyNavRightBtn.classList.remove('terms__nav-btn-active');
        }
      };
  
      const scrollNavRight = () => {
        privacyNav.scrollTo({
          top: 0,
          left: privacyNav.scrollLeft + 200,
          behavior: 'smooth',
        });
  
        setTimeout(() => {
          checkWindowWidth()

          if (privacyNav.clientWidth + privacyNav.scrollLeft + 10 > privacyNav.scrollWidth) {
            privacyNavRightBtn.classList.remove('terms__nav-btn-active');
          }
        }, 300);
      };
  
      const scrollNavLeft = () => {
        privacyNav.scrollTo({
          top: 0,
          left: privacyNav.scrollLeft - 200,
          behavior: 'smooth',
        });
  
        setTimeout(() => {
          checkWindowWidth()
          if (privacyNav.scrollLeft <= 10) {
            privacyNavLeftBtn.classList.remove('terms__nav-btn-active');
          }
        }, 300);
      };
  
      const getOffset = (element) => {
        const elementRect = element?.getBoundingClientRect();
        return elementRect?.top;
      };
  
      const listenToScroll = () => {
        const heightToStick = getOffset(document.querySelector('.terms__content'));
  
        heightToStick <= 190
          ? privacyNavContainer.classList.add('terms__nav-fixed')
          : privacyNavContainer.classList.remove('terms__nav-fixed');
      };
  
      const isScrolledIntoView = (el) => {
        let rect = el.getBoundingClientRect();
        let elemTop = rect.top;
        return elemTop;
      };
  
      const scrollFn = () => {
        setTimeout(() => {
            checkWindowWidth()
        }, 300)
        let lastVisible = null;
        const elements = [];
        document.querySelectorAll('.terms__nav-item').forEach((el) => {
          if (el) {
            elements.push({
              el: el.getAttribute('data-scroll-to'),
              pos: null,
              isVisible: null,
            });
          }
        });
  
        for (let element of elements) {
          const height = cookieHeigth ? cookieHeigth + 170 : 170;
          let offset = height;
  
          element.pos = isScrolledIntoView(document.querySelector(element.el));
          element.isVisible = element.pos < offset;
          if (element.isVisible) lastVisible = element.el;
        }
  
        let current = document.querySelector(`.terms__nav-item[data-scroll-to='${lastVisible}']`);
        if (lastVisible && current) {
          document.querySelectorAll('.terms__nav-item').forEach((el) => {
            el.classList.remove('terms__nav-item-active');
          });
  
          current.classList.add('terms__nav-item-active');
  
          const wrapScrollLeft = privacyNav.scrollLeft;
          const left = current.offsetLeft - (privacyNav.clientWidth / 2) + (current.clientWidth / 2);
          privacyNav.scrollTo({
            top: 0,
            left: left,
            behavior: 'smooth',
          });
        } else {
          document.querySelectorAll('.terms__nav-item').forEach((el) => {
            el.classList.remove('terms__nav-item-active');
          });
        }
      };
  
      window.addEventListener('resize', checkWindowWidth, true);
  
      privacyNavLeftBtn.addEventListener('click', scrollNavLeft);
      privacyNavRightBtn.addEventListener('click', scrollNavRight);
  
      window.addEventListener('scroll', listenToScroll);
      window.addEventListener('scroll', scrollFn);

      checkWindowWidth()
  
      return () => {
        window.removeEventListener('resize', checkWindowWidth, true);
        privacyNavLeftBtn.removeEventListener('click', scrollNavLeft);
        privacyNavRightBtn.removeEventListener('click', scrollNavRight);
        window.removeEventListener('scroll', listenToScroll);
        window.removeEventListener('scroll', scrollFn);
      };
    }, [cookieHeigth]);
  
    const scrollToEl = (el , cookieHeigth) => {
      const navFixed = privacyNavContainerRef.current.classList.contains('terms__nav-fixed');

      let height = cookieHeigth ? cookieHeigth + 150 : 150

      const style = getComputedStyle(privacyNavContainerRef.current);
      const margin = parseInt(style.margin, 10)
      if (!navFixed) {
        height += privacyNavContainerRef.current.offsetHeight + margin * 2;
      }

      window.scrollTo({
        top: window.scrollY + el.getBoundingClientRect().top - height,
        behavior: 'smooth',
      });
    };
  
    const handleScrollTo = (e , cookieHeigth) => {
      e.stopPropagation()
      const scrollToSelector = e.target.getAttribute('data-scroll-to');
      const scrollToElement = document.querySelector(scrollToSelector);
  
      if (scrollToElement !== null) {
        scrollToEl(scrollToElement, cookieHeigth);
      }
    };


    return (
        <motion.section className='terms'
            initial={{  opacity: 0 }}
            animate={{  opacity: 1, transition: {duration: 1} }}
            exit={{ opacity: 0, transition: {duration: 1}}}
        >
            <div className="terms__wrapper">
                <h1 className='terms__title'>{t(`termsLayout.${data.title}.title`)}</h1>
                <div className="terms__update">
                    <div className="terms__update-inner">
                      <div className="terms__update-date">{t('termsLayout.upd')}</div>
                    </div>
                    {
                      data.subtitle &&
                      <p>{t(`termsLayout.${data.title}.subtitle`)}</p>
                    }
                </div>
                <div className="terms__nav" ref={privacyNavContainerRef}>
                    <div className="container">
                        <div className="terms__nav-inner">
                            <div className="terms__nav-btn terms__nav-btn-left" ref={privacyNavLeftBtnRef}>
                              <ArrowRight />
                            </div>
                            <ul className='terms__nav-items' ref={privacyNavRef}>
                                {
                                    data.navLinks.map((item , i) => (
                                        <li
                                            key={item} 
                                            onClick={e =>  handleScrollTo(e, cookieHeigth)} 
                                            className="terms__nav-item scroll-to" data-scroll-to={`.chapter-${i}`}
                                        >
                                            <div>
                                              {t(`termsLayout.${data.title}.nav.${i}`)}
                                            </div>
                                        </li>
                                    ))
                                }
                            </ul>
                            <div className="terms__nav-btn terms__nav-btn-right" ref={privacyNavRightBtnRef}>
                                <ArrowRight/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="terms__content">
                    {children}
                </div>
            </div>
        </motion.section>
    )
}

export default TermsLayout;