import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

// const phoneRegExp = /^(\+?3)?8?(0\d{9})$/;

const useFormSchema = () => {

  const {t} = useTranslation();

  const validationSchema = Yup.object({
    name: Yup.string()
      .required('contacts.error.required')
      .min(2, 'contacts.error.min')
      .matches(/^[A-Za-zА-Яа-яЁёЇїІіЄєҐґ\s-]+$/, 'contacts.error.invalid')
      .matches(/[^\s]/, 'contacts.error.invalid'),
    email: Yup.string()
      .required('contacts.error.required')
      .email('contacts.error.email')
      .matches(/^[A-Za-z0-9]+([.][A-Za-z0-9]+)*@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/, 'contacts.error.email'),
    phone: Yup.string()
      .required('contacts.error.required')
      .matches(/^[0-9+()-]+$/, 'contacts.error.phone')
      .min(7, 'contacts.error.phone'),
    company: Yup.string()
      .required('contacts.error.required')
      .min(2, 'contacts.error.min')
      .matches(/^[A-Za-zА-Яа-яЁёЇїІіЄєҐґ\s"'0-9-]+$/, 'contacts.error.invalid')
      .matches(/[^\s]/, 'contacts.error.invalid'),
    task: Yup.string()
      .required('contacts.error.required')
      .min(2, 'contacts.error.min')
      .matches(/^[A-Za-zА-Яа-яЁёЇїІіЄєҐґ\s"'0-9.,()\@?!='_-]+$/, 'contacts.error.invalid')
      .matches(/[^\s]/,  'contacts.error.invalid'),
  });

  return {validationSchema}
}

export default useFormSchema;