import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next';

function It() {

  const  {i18n} = useTranslation();
  const lang = i18n.language;
  
  const linesSymbols = lang.startsWith('en');
  
    const printSentence = (id, sentence, speed = 30) => {
        let index = 0;
        let element = document.getElementById(id);
        
        let timer = setInterval(function() {
          const char = sentence[index];
          
          if (char === '<') {
            index = sentence.indexOf('>', index);  // skip to greater-than
          }
          
          element.innerHTML = sentence.slice(0, index);
          
          if (++index === sentence.length) {
            clearInterval(timer);
          }
        }, speed);
    } 

    useEffect(() => {
      if (linesSymbols) {
        printSentence('contentDiv',
        `<div><b><</b><i>h2</i><b>></b>Services<b><／</b><i>h2</i><b>></b></div><div><b><</b><i>ul</i><b>></b></div><div class="directions__it-ul"><div><b><</b><i>li</i><b>></b>Website development.<b><／</b><i>li</i><b>></b></div><div><b><</b><i>li</i><b>></b>Analysis of existing source codes.<b><／</b><i>li</i><b>></b></div><div><b><</b><i>li</i><b>></b>Analysis of cloud infrastructure.<b><／</b><i>li</i><b>></b></div><div><b><</b><i>li</i><b>></b>Refinement of projects.<b><／</b><i>li</i><b>></b></div></div><div ><b><／</b><i>ul</i><b>></b></div>`
        )
      }
      else {
        printSentence('contentDiv',
        `<div><b><</b><i>h2</i><b>></b>Послуги<b><／</b><i>h2</i><b>></b></div><div><b><</b><i>ul</i><b>></b></div><div class="directions__it-ul"><div><b><</b><i>li</i><b>></b>Створення сайтів.<b><／</b><i>li</i><b>></b></div><div><b><</b><i>li</i><b>></b>Аналіз поточних вихідних кодів.<b><／</b><i>li</i><b>></b></div><div><b><</b><i>li</i><b>></b>Аналіз хмарної інфраструктури.<b><／</b><i>li</i><b>></b></div><div><b><</b><i>li</i><b>></b>Доопрацювання проектів.<b><／</b><i>li</i><b>></b></div></div><div ><b><／</b><i>ul</i><b>></b></div>`
        )
      }
    }, [])

    return (
        <div className="directions__it">
            <div className="directions__it-nums">
                <div className="directions__it-num">1</div>
                <div className="directions__it-num">2</div>
                <div className="directions__it-num">3</div>
                <div className="directions__it-num">4</div>
                <div className="directions__it-num">5</div>
                <div className="directions__it-num">6</div>
                <div className="directions__it-num">7</div>

            </div>
            <div id='contentDiv' className="directions__it-lines">

            </div>
        </div>
    )
}

export default It