import React, { useState , useEffect, useRef} from 'react'
import { Link, useLocation } from 'react-router-dom'
import PageLine from '../PageLine'
import {motion} from 'framer-motion'
import { Trans, useTranslation } from 'react-i18next'


const About = ({videoIsLoop, routePush, disableLink}) => {
	const {t} = useTranslation();
	return (
		<motion.section className="about"
			initial={{  opacity: 0 }}
			animate={videoIsLoop ? {  opacity: 1, transition: 2 } : {opacity: 0}}
			exit={{ opacity: 0, transition: {duration: 1, delay: 1}}}
		>
			<picture>
				<source srcSet={require('../../assets/img/about-bg-mob.webp')} type="image/webp"/>
				<img className='home__bg-mob' src={require('../../assets/img/about-bg-mob.png')} alt="home background" />
			</picture>
			<motion.div className="about__wrapper"
				initial={{ x: '-20%', opacity: 0}}
				animate={videoIsLoop ? {  x: 0 , opacity: 1,transition: {duration: 1}} : { x: '-20%', opacity: 0}}
				exit={{ x: '-100%', opacity: 0,transition: { delay: 1} }}
			>
				<div className="about__page">
					<PageLine name={t('about.line-title')}/>
				</div>
				<h1 className="about__title">
					<Trans i18nKey="about.title" components={{ 1: <br /> }} />
				</h1>
				<h4 className="about__subtitle">
					{t('about.subtitle')}
				</h4>
				<div className="about__button">
					<Link 
						to='/about-us'
						onClick={(e) => routePush(e, true, '/about-us')} 
						className={`button button-black button--bestia ${disableLink && 'link-disable'}`}
					>
						<span>{t('about.button')}</span>
						<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M6.63673 7.7143L6.63673 6.04788L14.541 6.04788V13.9522H12.8746V8.89281L7.22599 14.5414L6.04747 13.3629L11.6961 7.7143L6.63673 7.7143Z" fill="white"/>
						</svg>
						<div className="button__bg"></div>
					</Link>
				</div>
			</motion.div>
		</motion.section>
	)
}

export default About