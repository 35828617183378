import React from 'react'
import img from '../../assets/img/icons/form-attention.svg'
import { useTranslation } from 'react-i18next'

const FormError = ({msg}) => {
  const {t} = useTranslation();
  return (
    <div className='contacts__form-input-message'>
      <img src={img} alt="form attention" /> {t(msg)}
    </div>
  )
}

export default FormError