import React, { useContext, useEffect } from 'react'
import { LocationContext } from './LocationContext'
import { useLocation } from 'react-router-dom'
import {motion} from 'framer-motion'

import splash from '../assets/img/splash.png'
// import { mobileDetect } from '../utils/helpersFunction'

function PageLine({name}) {
	// const touchDevice = mobileDetect();
	return (
		<BlinkComponent name={name}/>
	)
}

const BlinkComponent = ({ name }) => {
	const contextLocation = useContext(LocationContext);
	const { pathname } = useLocation();
  
	return (
	  <div className="page-name">
		<span>{name}</span>
		<div className="page-name__line">
		  <motion.img 
			initial={{ opacity: 0 }}
			animate={contextLocation !== pathname && pathname?.split('/').length < 3 ? 
			  { opacity: [1, 1, 0], right: 0, transition: { duration: pathname === '/about' ? 0.5 : 0.7, ease: 'easeInOut', opacity: { duration: 1 } } } : 
			  { opacity: 0 }
			}
			className='page-name__splash' src={splash} alt="splash"
		  />
		</div>
	  </div>
	);
  };

//   const AnimationComponent = ({ name , scrollCount}) => {

// 	return (
// 	  <div className="page-name">
// 		<span>{name}</span>
// 		<div className="page-name__line page-name__line-scrolled">
// 			<div className="page-name__line-wrapper"
// 			>
// 				<div className="page-name__line-inner"
// 					style={{
// 						opacity: scrollCount === 0 || scrollCount === 9 ? 0 : 1,
// 						translate: `${10 * scrollCount}%`,
// 					}}
// 				>
// 					<img 
// 						className='page-name__splash' src={splash} alt="splash"
// 					/>
// 				</div>
// 			</div>
// 		</div>
// 	  </div>
// 	);
//   };
  

export default PageLine