import Cookies from 'js-cookie';
import ToggleSwitch from './ToggleSwitch';
import { useState, useEffect, useRef, useLayoutEffect } from 'react';
import settings from '../assets/img/icons/settings.svg';
import cookie from '../assets/img/icons/Cookie.svg';
import { Link, useLocation } from 'react-router-dom';

import close from '../assets/img/icons/cookie-close.svg'
import { useTranslation } from 'react-i18next';

const CookiesCheck = ({routePush, setSettingsOpen, isSettingsOpen, setCookieHeight, disableLink, handleAnimationDisable, isCookiesVisible, setCookiesVisible}) => {

    const {t} = useTranslation();
    const location = useLocation()

    const cookieRef = useRef(null);

    useEffect(() => {
        if (cookieRef.current) {
            setCookieHeight(cookieRef.current.offsetHeight);
        }
    }, [ isCookiesVisible])

    useEffect(() => {
        return () => {
            setCookieHeight(0);
        }
    }, [isCookiesVisible])

    useEffect(() => {
        if (!Cookies.get('is_cookies_accept')) {
            setCookiesVisible(true);
        }
    }, []);

    const handleSetCookie = () => {
        Cookies.set('is_cookies_accept', true);
        setCookiesVisible(false);
        setSettingsOpen(false)
        cookieRef.current = null;
    };

    const handleDeclineCookie = () => {
        setSettingsOpen(false)
        setCookiesVisible(false);
        cookieRef.current = null;
    }

    const handlePopupChangeTerms = (e) => {
        if (location.pathname.includes('cases/')) {
            handleAnimationDisable()
        }
        routePush(e, true, '/terms')
        setSettingsOpen(false)
    }

    const handlePopupChangePolicy = (e) => {
        if (location.pathname.includes('cases/')) {
            handleAnimationDisable()
        }
        routePush(e, true, '/policy')
        setSettingsOpen(false)
    }

    const handlePopupTopChangeTerms = (e) => {
        if (location.pathname.includes('cases/')) {
            handleAnimationDisable()
        }

        routePush(e, true, '/terms')
    }

    const handlePopupTopChangePolicy = (e) => {
        if (location.pathname.includes('cases/')) {
            handleAnimationDisable()
        }

        routePush(e, true, '/policy')
    }

    return (
        <>
            {isCookiesVisible && (
                <>
                    {isSettingsOpen ? (
                        <div className="cookies-overlay">
                            <div className="cookies-popup">
                                <div
                                    className="cookies-popup__close"
                                    onClick={() => setSettingsOpen(false)}
                                >
                                    <img src={close} alt="Cookie close" />
                                </div>
                                <div className="cookies-popup__title">
                                    {t('cookies.cookies-inner.title')}
                                    <img src={cookie} alt="cookie" />
                                </div>
                                <div className="cookies-popup__subtitle">
                                    <p>
                                    {t('cookies.cookies-inner.top-text')}
                                    </p>
                                    <p>
                                        {t('cookies.cookies-inner.start')} {' '}
                                        <Link className="cookies-top-link" to="/terms" onClick={handlePopupChangeTerms}>
                                            {t('cookies.cookies-inner.terms')}
                                        </Link>
                                        {` ${t('cookies.cookies-top.and')} `}
                                        <Link className="cookies-top-link" to="/policy" onClick={handlePopupChangePolicy}>
                                            {t('cookies.cookies-inner.privacy')}
                                        </Link>
                                    </p>
                                </div>
                                <div className="cookies-popup__buttons">
                                    <div
                                        className="cookies-popup__button cookies-top__button_accept button-black button--bestia"
                                        onClick={handleSetCookie}
                                    >
                                        <span>{t('cookies.accept')}</span> 
                                        <div className="button__bg"></div>
                                    </div>
                                    <div
                                        className="cookies-popup__button cookies-top__button_decline link link--metis"
                                        onClick={handleDeclineCookie}
                                    >
                                        {t('cookies.decline')}
                                    </div>
                                </div>
                                <div className="cookies-popup__wrap">
                                    <div className="cookies-popup__caption-wrap">
                                        <div className="cookies-popup__caption">
                                        {t('cookies.cookies-inner.mandatory')}
                                        </div>
                                    </div>
                                    <div className="cookies-popup__text">                           
                                        {t('cookies.cookies-inner.mandatory-text')}
                                    </div>
                                </div>
                                <div className="cookies-popup__wrap">
                                    <div className="cookies-popup__caption-wrap">
                                        <div className="cookies-popup__caption">
                                            {t('cookies.cookies-inner.google')}
                                        </div>
                                        <ToggleSwitch
                                            className="cookies-popup__switch"
                                            checked={true}
                                        />
                                    </div>
                                    <div className="cookies-popup__text">
                                        {t('cookies.cookies-inner.google-text')}
                                    </div>
                                </div>
                                <div className="cookies-popup__wrap">
                                    <div className="cookies-popup__caption-wrap">
                                        <div className="cookies-popup__caption">
                                            {t('cookies.cookies-inner.marketing')}
                                        </div>
                                        <ToggleSwitch
                                            className="cookies-popup__switch"
                                            checked={true}
                                        />
                                    </div>
                                    <div className="cookies-popup__text">
                                        {t('cookies.cookies-inner.marketing-text')}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div ref={cookieRef} className="cookies-top">
                            <div className="container">
                                <div className="cookies-top__inner">
                                    <div className="cookies-top__text">
                                    {t('cookies.cookies-top.start')}
                                        {' '}
                                        <Link className={`cookies-top-link ${disableLink && 'link-disable'}`} to="/terms" onClick={(e) => handlePopupTopChangeTerms(e)}>{t('cookies.cookies-top.terms')}</Link> 
                                        {` ${t('cookies.cookies-top.and')} `}
                                        <Link className={`cookies-top-link ${disableLink && 'link-disable'}`} to="/policy" onClick={(e) => handlePopupTopChangePolicy(e)}>{t('cookies.cookies-top.privacy')}</Link>
                                    </div>
                                    <div className="cookies-top__buttons">
                                        <div
                                            className="cookies-top__button cookies-top__button_accept button-black button--bestia"
                                            onClick={handleSetCookie}
                                        >
                                            <span>{t('cookies.accept')}</span>
									        <div className="button__bg"></div>

                                        </div>
                                        <div
                                            className="cookies-top__button cookies-top__button_decline link link--metis"
                                            onClick={() =>
                                                setCookiesVisible(false)
                                            }
                                        >
                                            {t('cookies.decline')}
                                        </div>
                                        <div
                                            className="cookies-top__button cookies-top__button_settings"
                                            onClick={() =>
                                                setSettingsOpen(true)
                                            }
                                        >
                                            <img src={settings} alt="cookie settings" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </>
            )}
        </>
    );
};

export default CookiesCheck;
