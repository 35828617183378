import {useRef} from "react";

import playButton from '../assets/img/icons/play.svg'

export default function YouTubeFrame({video, width, height}) {
    const divRef = useRef(null);

    const onClick = () => {
        const iframe = document.createElement( "iframe" );
        iframe.setAttribute( "frameborder", "0" );
        iframe.setAttribute( "allowfullscreen", "1");
        iframe.setAttribute('allow', 'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture');
        iframe.style.width = width;
        iframe.style.height = height;
        iframe.setAttribute( "src", `https://www.youtube.com/embed/${video}?rel=0&showinfo=1&autoplay=1` );
        if (divRef.current) {
            divRef.current.innerHTML = "";
            divRef.current.appendChild(iframe);
        }
    }


    return (
        <div ref={divRef} className="youtube-frame">
            <span onClick={onClick} className="youtube-play">
                <span className="youtube-play__content"><img src={playButton} alt="play button" /></span>
            </span>
            <picture>
                <source srcSet={require('../assets/img/video-preview.webp')} type="image/webp"/>
                <img onClick={onClick} src={require('../assets/img/video-preview.jpg')} alt="YouTube Video Thumbnail" width={width} height={height} className="youtube-preview" />
            </picture>
        </div>
    );
}