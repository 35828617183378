import './styles/globals.scss'
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import i18n from './config/i18n';
import TagManager from 'react-gtm-module'
import App from './App';
import HelmetBlock from './components/HelmetBlock';

const tagManagerArgs = {
    gtmId: 'GTM-PHXSDR93'
}

TagManager.initialize(tagManagerArgs)

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <Router>
        <HelmetBlock/>
        <App />
    </Router>
);
