import React from 'react'
import check from '../../assets/img/icons/form-check.svg'
import { useTranslation } from 'react-i18next';

const FormMessage = ({status}) => {

    const {t} = useTranslation();

    const {sendingStatus} = status;

    // console.log(sendingStatus)

    let block = null;

    switch (sendingStatus) {
        case 'loading':
        block = <Loader />;
        break;
        case 'error':
        block = <ErrorMessage t={t}/>;
        break;
        case 'done':
        block = <DoneMessage t={t}/>;
        break;
        default:
        block = <Loader />;
    }
    return (
        <div className='contacts__message'>
            {block}
        </div>
    )
}

const DoneMessage = ({t}) => {
    return (
        <div className="contacts__message-block">
            <img src={check} alt="form-check" />
            <p>{t('contacts.notification.title')}</p>
            <span>{t('contacts.notification.text')}</span>
        </div>
    )
}

const ErrorMessage = ({t}) => {
    return (
        <div className="contacts__message-block">
            <span>{t('contacts.notification.error')}</span>
        </div>
    )
}

const Loader = () => {
    return (
        <div className="contacts__load-container">
            <div className="contacts__load-speeding-wheel"></div>
        </div>
    )
}

export default FormMessage