
export const scrollToTop = () => {
    const element = document.documentElement || document.body;
    element.scrollIntoView({ behavior: "instant", block: "start" });
}



export const mobileDetect = () => {
    const hasTouchScreen = ('maxTouchPoints' in navigator && navigator.maxTouchPoints > 0) || ('msMaxTouchPoints' in navigator && navigator.msMaxTouchPoints > 0);
    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

    if (hasTouchScreen || isMobile) {
      // Устройство является мобильным
      return true
    } else {
      // Устройство является десктопным
      return false
    }
  };
  