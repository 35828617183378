import React from 'react'
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom'

// import caseImg from '../assets/img/cases-1.png'

function CasesSlide({data, handleButtonClick, setCurrentSlide}) {

    const {t} = useTranslation();

    const handleClick = (route) => {
        handleButtonClick(route);
        setCurrentSlide(data.num - 1);
    }

    return (
        <div className="cases__slide">
            {/* <div className="cases__slide-blur"></div> */}
            <div className="cases__slide-num">{`${data.num < 10 ? '0' + data.num : data.num}`}</div>
            <div className="cases__slide-top">
                <h2 className="cases__slide-title">{data.title}</h2>
                <div className="cases__slide-wrap">
                    <div className="cases__slide-data cases__slide-data-mob">
                        <span>{t(`cases.${data.title}.category`)}</span> <div className="cases__slide-line"></div><span>{data.year}</span>
                    </div>
                    {
                        data.title === 'Rihon' || data.title === 'BrokerNear' ? 
                        <a href={data.link.full} rel='noreferrer' target='_blank' className={
                            data.link.full === 'coming soon' || data.link.full === 'closed beta' ?  'cases__slide-link cases__slide-link-coming link link--iocaste' :
                            'cases__slide-link  link link--iocaste'
                        }>
                            {data.link.short}
                            <svg className="link__graphic link__graphic--slide" width="300%" height="100%" viewBox="0 0 1200 60" preserveAspectRatio="none">
                                <path d="M0,56.5c0,0,298.666,0,399.333,0C448.336,56.5,513.994,46,597,46c77.327,0,135,10.5,200.999,10.5c95.996,0,402.001,0,402.001,0"></path>
                            </svg>
                        </a> 
                        : 
                        <div className={
                            data.link.full === 'coming soon' || data.link.full === 'closed beta' ?  'cases__slide-link cases__slide-link-coming link link--iocaste' :
                            'cases__slide-link  link link--iocaste'
                        }>
                            {data.link.short}
                            <svg className="link__graphic link__graphic--slide" width="300%" height="100%" viewBox="0 0 1200 60" preserveAspectRatio="none">
                                <path d="M0,56.5c0,0,298.666,0,399.333,0C448.336,56.5,513.994,46,597,46c77.327,0,135,10.5,200.999,10.5c95.996,0,402.001,0,402.001,0"></path>
                            </svg>
                        </div>
                    }
                </div>
            </div>
            <h5 className="cases__slide-subtitle">
                {t(`cases.${data.title}.text`)}
            </h5>
            <Link 
                className="cases__slide-picture"
                to={data.href}
                onClick={() => handleClick(data.href)}
                >
                <picture>
                    <source srcSet={require(`../assets/img/cases-${data.num}.webp`)} type="image/webp"/>
                    <img className='cases__slide-img' src={require(`../assets/img/cases-${data.num}.png`)} alt="Work case"/>
                </picture>
                <div className="cases__slide-overlay"></div>
                <div className="cases__slide-bottom">
                    <div className="cases__slide-data">
                        {t(`cases.${data.title}.category`)} <div className="cases__slide-line"></div>{data.year}
                    </div>	
                    <div 
                        className="cases__button">
                            <div  className='button button-case button--bestia'>
                                <span>{t('cases.button')}</span>
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6.63673 7.7143L6.63673 6.04788L14.541 6.04788V13.9522H12.8746V8.89281L7.22599 14.5414L6.04747 13.3629L11.6961 7.7143L6.63673 7.7143Z" fill="white"/>
                                </svg>
                                <div className="button__bg"></div>
                            </div>
                    </div>
                </div>
            </Link>
        </div>
  )
}

export default CasesSlide;