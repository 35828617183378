import React from 'react'
import TermsLayout from '../TermsLayout'

import { policyLists } from '../../utils/content'
import { Trans, useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

const Policy = ({routePush, cookieHeigth}) => {
    const {t} = useTranslation();
    const data = {
        navLinks : ['Тлумачення та визначення', 'Збір та використання ваших персональних даних', 'Конфіденційність дітей', 'Посилання на інші веб-сайти', 'Зміни до цієї Політики конфіденційності'],
        title: 'Політика конфіденційності',
        subtitle: 'Ця Політика конфіденційності описує нашу політику та процедури щодо збору, використання та розкриття Вашої інформації під час користування Сервісом, а також розповідає про Ваші права на конфіденційність і про те, як закон захищає Вас. Ми використовуємо Ваші персональні дані для надання та вдосконалення Сервісу. Користуючись Сервісом, Ви погоджуєтесь на збір та використання інформації відповідно до цієї Політики конфіденційності.'
    }
     

    return (
        <TermsLayout  data={data} cookieHeigth={cookieHeigth}>
            <div className="terms__content-chapter chapter-0">
                <h2 className="terms__content-chapter-title">{t('policy.terms.title')}</h2>
                <div className="terms__content-chapter-text">
                    <h4>{t('policy.terms.h1')}</h4>
                    <p>
                    {t('policy.terms.p1')}
                    </p>
                    <h4>{t('policy.terms.h2')}</h4>
                    <p>
                    {t('policy.terms.p2')}
                    </p>
                    <ul>
                        {
                            policyLists.list_1.map((item, i) => (
                                <li key={i}>
                                    <Trans i18nKey={`policy.terms.ul1.${i}`} components={{ 1: <strong/> }} />
                                </li>
                            ))
                        }
                    </ul>
                </div>
            </div>
            <div className="terms__content-chapter chapter-1">
                <h2 className="terms__content-chapter-title">{t('policy.data.title')}</h2>
                <div className="terms__content-chapter-text">
                    <h4>{t('policy.data.h1')}</h4>
                    <p>
                    {t('policy.data.p1')}
                    </p>
                    <ul>
                        <strong>
                            {
                                policyLists.list_2.map((item, i) => (
                                    <li key={i}>{t(`policy.data.ul1.${i}`)}</li>
                                ))
                            }
                        </strong>
                    </ul>
                    <h4>{t('policy.data.h2')}</h4>
                    <p>{t('policy.data.p2')}</p>
                    <p>{t('policy.data.p3')}</p>
                    <p>{t('policy.data.p4')}</p>
                    <p>{t('policy.data.p5')}</p>
                    <h4>{t('policy.data.h3')}</h4>
                    <p>{t('policy.data.p6')}</p>
                    <ul>
                        {
                            policyLists.list_3.map((item, i) => (
                                <li key={i}>
                                    <Trans i18nKey={`policy.data.ul2.${i}`} components={{ 1: <strong/> }} />
                                </li>
                            ))
                        }
                    </ul>
                    <p>{t('policy.data.p7')}</p>
                    <p>{t('policy.data.p8')}</p>
                    <p><strong>{t('policy.data.p9')}</strong></p>
                    <ul>
                        {
                            policyLists.list_4.map((item, i) => (
                                <li key={i}>{t(`policy.data.ul3.${i}`)}</li>
                            ))
                        }
                    </ul>
                    <p><strong>{t('policy.data.p10')}</strong></p>
                    <ul>
                        {
                            policyLists.list_5.map((item, i) => (
                                <li key={i}>{t(`policy.data.ul4.${i}`)}</li>
                            ))
                        }
                    </ul>
                    <p><strong>{t('policy.data.p11')}</strong></p>
                    <ul>
                        {
                            policyLists.list_6.map((item, i) => (
                                <li key={i}>{t(`policy.data.ul5.${i}`)}</li>
                            ))
                        }
                    </ul>
                    <p>{t('policy.data.p12')}</p>
                    <h4>{t('policy.save.title')}</h4>
                    <p>{t('policy.save.p1')}</p>
                    <ul>
                        {
                            policyLists.list_7.map((item, i) => (
                                <li key={i}>
                                    <Trans i18nKey={`policy.save.ul1.${i}`} components={{ 1: <strong/> }} />
                                </li>
                            ))
                        }
                    </ul>
                    <p>{t('policy.save.p2')}</p>
                    <ul>
                        {
                            policyLists.list_8.map((item, i) => (
                                <li key={i} >
                                    <Trans i18nKey={`policy.save.ul2.${i}`} components={{ 1: <strong/> }} />
                                </li>
                            ))
                        }
                    </ul>
                    <h4>{t('policy.save.h1')}</h4>
                    <p> 
                    {t('policy.save.p3')}
                    </p>
                    <h4>{t('policy.save.h2')}</h4>
                    <p>{t('policy.save.p4')}</p>
                    <p>{t('policy.save.p5')}</p>
                    <p>{t('policy.save.p14')}</p>
                    <h4>{t('policy.save.h4')}</h4>
                    <p>{t('policy.save.p6')}</p>
                    <p>{t('policy.save.p7')}</p>
                    <p>{t('policy.save.p8')}</p>
                    <p>{t('policy.save.p9')}</p>
                    <h4>{t('policy.save.h5')}</h4>
                    <p> <Trans i18nKey='policy.save.p10' components={{ 1: <strong/> }} />
                    </p>
                    <p> 
                        <Trans i18nKey='policy.save.p11' components={{ 1: <strong/> }} />
                    </p>
                    <p>
                        <Trans i18nKey='policy.save.p12' components={{ 1: <strong/> }} />
                    </p>
                    <ul>
                        {
                            policyLists.list_9.map((item, i) => (
                                <li key={i}>{t(`policy.save.ul3.${i}`)}</li>
                            ))
                        }
                    </ul>
                    <p> 
                        <Trans i18nKey='policy.save.p13' components={{ 1: <strong/>, 2: <br/> }} />
                    </p>
                </div>
            </div>
            <div className="terms__content-chapter chapter-2">
                <h2 className="terms__content-chapter-title">{t('policy.confident.title')}</h2>
                <div className="terms__content-chapter-text">
                    <p>
                        <Trans i18nKey='policy.confident.p1' components={{ 1: <br/> }} />
                    </p>
                </div>
            </div>
            <div className="terms__content-chapter chapter-3">
                <h2 className="terms__content-chapter-title">{t('policy.links.title')}</h2>
                <div className="terms__content-chapter-text">
                    <p>
                        <Trans i18nKey='policy.links.p1' components={{ 1: <br/> }} />
                    </p>
                </div>
            </div>
            <div className="terms__content-chapter chapter-4">
                <h2 className="terms__content-chapter-title">{t('policy.policy.title')}</h2>
                <div className="terms__content-chapter-text">
                    <p>
                    <Trans i18nKey='policy.policy.p1' components={{ 1: <br/> }} />
                    </p>
                </div>
            </div>
            <div className="terms__content-chapter">
                <h2 className="terms__content-chapter-title">{t('terms.contact.title')}</h2>
                <div className="terms__content-chapter-text">
                    <p>
                        {t('terms.contact.p1')}
                    </p>
                    <ul>
                        <li>{t('terms.contact.ul1.0')} <a href="mailto:hello@uniqit.group"><strong>hello@uniqit.group</strong></a></li>
                        <li>{t('terms.contact.ul1.1')} <a href="https://uniqit.group/contacts"><strong>https://uniqit.group/contacts</strong></a></li>
                        <li>{t('terms.contact.ul1.2')} <a href="tel:+380502255535"><strong>+38 (050) 225-55-35</strong></a></li>
                    </ul>
                </div>
            </div>
            <div className="case__button terms__content-button">
                <Link to='/contacts' onClick={(e) => routePush(e, true ,'/contacts')} className='button button-black button--bestia'>
                    <span>{t('terms.button')}</span>
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6.63673 7.7143L6.63673 6.04788L14.541 6.04788V13.9522H12.8746V8.89281L7.22599 14.5414L6.04747 13.3629L11.6961 7.7143L6.63673 7.7143Z" fill="white"/>
                        </svg>
                    <div className="button__bg"></div>
                </Link>
            </div>
        </TermsLayout>
    )
}

export default Policy